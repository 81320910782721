import React, { useState } from "react";

import NavTop from '../components/layoutParts/mobile/MobileHeader';
import Footer from '../components/layoutParts/Footer';
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const MobileMainLayout = ({children, title, Products}) =>{
    const [showMenu, setShowMenu] = useState(false);
    return(
        <>
            <NavTop Products={Products} title={title}/>
            <div>
                {children}
            </div>
            <div className="bg-black p-4 text-white font-medium border-b flex justify-between text-base" onClick={() => setShowMenu(!showMenu)}>
                <span>More About Clothology</span>
                <span className="text-2xl">
                    {   showMenu?<BiChevronUp /> :  <BiChevronDown /> }               
                </span>
            </div>
            <div>
                {showMenu && (
                    <Footer/>
                )}
            </div>
        </>
    )
}

export default MobileMainLayout;