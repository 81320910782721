import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';
import { FaRegHeart, FaBagShopping, FaUser } from 'react-icons/fa6';
import NavMenus from './NavMenus';
import { useAuth } from '../user/AuthContext';
import { useGlobal } from '../../global/GlobalContext';
import API_BASE_URL from '../../global/apiConfig';
import HeaderSearchBar from '../HeaderSearchBar';

const NavTop = () => {
  const { userToken, logout } = useAuth();
  // const [cartItems, setCartItems] = useState([]);
  // const [wishlistItems, setWishlistItems] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const { cart, wishlist, token } = useGlobal();

  // useEffect(() => {
  //   if (!token) return;

  //   const fetchCartAndWishlist = async () => {
  //     try {
  //       // Fetch Cart
  //       const cartResponse = await fetch(`${API_BASE_URL}/get_customer_cart`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       if (cartResponse.ok) {
  //         const cartData = await cartResponse.json();
  //         setCartItems(cartData.cart_items || []);
  //       }

  //       // Fetch Wishlist
  //       const wishlistResponse = await fetch(`${API_BASE_URL}/get_customer_wishlist`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       if (wishlistResponse.ok) {
  //         const wishlistData = await wishlistResponse.json();
  //         setWishlistItems(wishlistData.wishlist || []);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchCartAndWishlist();
  // }, [token]);

  const cartCount = cart.length;
  const wishlistCount = wishlist.length;

  return (
    <>
      {/* Header */}
      <div className="hidden md:block bg-black header_top">  
        <div className="max-w-7xl mx-auto p-4 md:flex justify-between items-center text-white hidden">
          <div className="flex gap-3 capitalize">
            <Link className="text-xs text-slate-300">Offers</Link>
            <Link className="text-xs text-slate-300">Our fanbook</Link>
            <Link className="text-xs text-slate-300">Clotribes membership</Link>
            <Link className="text-xs text-slate-300">Find a store near me</Link>
          </div>
          <div className="flex gap-3 capitalize">
            <Link className="text-xs text-slate-300">Contact us</Link>
            <Link className="text-xs text-slate-300">Track your order</Link>
          </div>
        </div>
      </div>

      {/* Navbar */}
      <header className="bg-black md:bg-white border-b border-black z-50 w-full sticky top-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-wrap justify-between items-center">
          {/* mobile start */}
          <div className='md:hidden px-2 py-4'>
          <button
              className="text-white text-lg"
              onClick={() => setShowMenu(!showMenu)}
            >
              ☰
            </button>
            <span className='text-gray-300 ml-4  text-lg' onClick={() => {
                  window.location.href = process.env.PUBLIC_URL || '/';
                }}>CIG</span>
                {/* <img
                onClick={() => {
                  window.location.href = process.env.PUBLIC_URL || '/';
                }}
                className="w-48 h-30 cursor-pointer block md:hidden"
                src={`${process.env.PUBLIC_URL || ''}/logoCloth.jpg`}
                alt="Logo"
              /> */}
            </div>
<div className="mobile_menu p-2 md:hidden">
            {showMenu && (
              <div className="menu_items">
                <div className="flex gap-3 flex-col">
                  {userToken ? (
                    <div className="flex gap-3 flex-col">
                      <span className="font-bold text-gray-800">
                        Hey, <Link to={'/myaccount'}>User</Link>
                      </span>
                      <p className='text-gray-600 text-sm'>SHOP IN</p>
                      <NavMenus />
                      <button
                        onClick={logout}
                        className="text-red-500 hover:text-red-600 font-semibold"
                      >
                        Logout
                      </button>
                    </div>
                  ) : (
                    <>
                      <Link to="/login">
                        <p className='md:hidden text-gray-600'>Hey There!</p>
                        <p className='text-black text-sm font-bold'>LOGIN / SIGNUP</p>
                      </Link>
                      <p className='text-gray-600 text-xs'>SHOP IN</p>
                      <NavMenus />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* mobile end */}
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                onClick={() => {
                  window.location.href = process.env.PUBLIC_URL || '/';
                }}
                className="w-60 h-30 cursor-pointer hidden md:block"
                src={`${process.env.PUBLIC_URL || ''}/images/logo.png`}
                alt="Logo"
              />
              
            </div>
            <div className="hidden md:block">
              <NavMenus />
            </div>
          </div>
          <HeaderSearchBar />
          {/* <div className="hidden md:block">
            <input
              type="text"
              placeholder="Search"
              className="block w-full pl-3 py-2 pr-12 sm:text-sm rounded-[4px] border-[0.2px] border-black border-opacity-20 focus:outline-none focus:border-opacity-80"
            />
          </div> */}
          <div className="flex items-center gap-5">
            <div className="hidden md:flex">
              {userToken ? (
                <div className="items-center gap-3">
                  <div className="relative font-bold text-gray-800 group">
                    <Link to={'/myaccount'}>
                      <FaUser />
                    </Link>

                    <span className="hidden group-hover:block top-full left-0 border bg-white absolute p-2">
                      <Link className="text-black text-sm font-light text-nowrap" to={'/myaccount'}>My Account</Link>

                        <button
                          onClick={logout}
                          className="text-black text-sm font-light"
                        >
                          Logout
                        </button>
                    </span>                  
                  </div>
                </div>
              ) : (
                <Link to="/login">LOGIN / SIGNUP</Link>
              )}
            </div>

            <Link to="/wishlist" className="relative">
              <FaRegHeart className='text-white md:text-black text-lg md:text-base' />
              {wishlistCount > 0 && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-xs text-white bg-black rounded-full w-4 h-4 flex items-center justify-center">
                  {wishlistCount}
                </span>
              )}
            </Link>

            <Link to="/cart" className="relative">
              <FaBagShopping className='text-white md:text-black text-lg md:text-base' />
              {cartCount > 0 && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-xs text-black border bg-white rounded-full w-4 h-4 flex items-center justify-center">
                  {cartCount}
                </span>
              )}
            </Link>
          </div>

          
        </div>
      </header>
    </>
  );
};

export default NavTop;
