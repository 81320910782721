import React from 'react';
import MobileMainLayout from '../../layout/MobileMainLayout';
import CatMenMobile from '../../components/mobileComponents/CatMenMobileBanner';
import SeparateCatMenMobile from '../../components/mobileComponents/SeparateCatMenMobile';
import AllCatMenMobile from '../../components/mobileComponents/AllCatMobile';

const MensHome = () => {
  return (
    <MobileMainLayout title="Men's Fashion">
        <CatMenMobile />
        <img src='images/mobile/coupon.jpg' />
        <div className='my-3'>
          <h2 className='font-semibold text-sm ms-3 mb-3'>Men's Footwear</h2>
          <SeparateCatMenMobile slug="mens-footwear" />
        </div>

        <div className='my-3'>
          <h2 className='font-semibold text-sm ms-3 mb-3'>Men's Topwear</h2>
          <SeparateCatMenMobile slug="mens-topwear" />
        </div>
        <div className='mb-3 ms-3'>
          <AllCatMenMobile slug="men" />
        </div> 
    </MobileMainLayout>
  );
};

export default MensHome;
