import React from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import BannerCards from '../../content/BannerCards';
import { Link } from 'react-router-dom';


const options = {
  loop:true,
  autoplay:true,
  autoplayTimeout:5000,
  autoplayHoverPause:true,
  smartSpeed:2000,
  margin:10,
  autoWidth:true,
  items:1,
}


const HomeBannerMobile = () => {
  return (
    <>
      {/* Hero Section */}
      {/*  */}
      <ReactOwlCarousel {...options} className='owl-theme owl_mobile' loop margin={10}>
      {BannerCards.map((card, index) => (
        <div className="item" key={index}>
          <Link to={card.btnLink} className="flex flex-col justify-end relative w-[350px] h-[400px] rounded-lg">
            <img
              className="w-full absolute h-full object-cover top-0 left-0 rounded-lg"
              src={card.imgSrc}
              alt={card.title}
            />
            {/* <div className="banner_card_inner relative px-3 bg-gradient-to-t from-black to-transparent">
                <h2 className="text-4xl font-bold text-white">{card.title}</h2>

            </div> */}
          </Link>
        </div>
      ))}
      </ReactOwlCarousel>
      {/*  */}
      
    </>
  )
}

export default HomeBannerMobile;
