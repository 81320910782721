const BannerCards = [
      {
        title: "OVERSIZED STARTING FROM 899",
        imgSrc: `${process.env.PUBLIC_URL}/images/Poster1.jpeg`,
        btnLink: "#",
      },
      {
        title: "OVERSIZED STARTING FROM 899",
        imgSrc: `${process.env.PUBLIC_URL}/images/clothology/hero1.jpeg`,
        btnLink: "#",
      },
      {
        title: "Women's Fashion",
        imgSrc: `${process.env.PUBLIC_URL}/images/clothology/hero2.jpeg`,
        btnLink: "#",
      },
      {
        title: "Women's Fashion",
        imgSrc: `${process.env.PUBLIC_URL}/images/clothology/hero3.jpeg`,      
        btnLink: "#",
      },
      {
        title: "Men's Fashion",       
        imgSrc: `${process.env.PUBLIC_URL}/images/clothology/hero4.jpeg`,        
        btnLink: "#",
      },
      {
        title: "Men's Fashion",       
        imgSrc: `${process.env.PUBLIC_URL}/images/clothology/hero5.jpeg`,       
        btnLink: "#",
      },
      {
        title: "Women's Fashion",      
        imgSrc: `${process.env.PUBLIC_URL}/images/clothology/hero6.jpeg`,     
        btnLink: "#",
      },
      {
        title: "Women's Fashion",      
        imgSrc: `${process.env.PUBLIC_URL}/images/clothology/hero7.png`,     
        btnLink: "#",
      },


]

export default BannerCards
