import React, { useEffect, useState } from 'react';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [socialStats, setSocialStats] = useState({
    facebook: null,
    instagram: null,
    twitter: null,
  });

  useEffect(() => {
    // Simulating API calls for social stats
    const fetchSocialStats = async () => {
      try {
        const facebookLikes = await fetchFacebookLikes();
        const instagramFollowers = await fetchInstagramFollowers();
        const twitterFollowers = await fetchTwitterFollowers();

        setSocialStats({
          facebook: facebookLikes,
          instagram: instagramFollowers,
          twitter: twitterFollowers,
        });
      } catch (error) {
        console.error('Error fetching social stats:', error);
      }
    };

    fetchSocialStats();
  }, []);

  const fetchFacebookLikes = async () => {
    // Replace with your API call or Facebook Graph API logic
    return 1200; // Mock data
  };

  const fetchInstagramFollowers = async () => {
    // Replace with your API call for Instagram data
    return 900; // Mock data
  };

  const fetchTwitterFollowers = async () => {
    // Replace with your API call for Twitter data
    return 750; // Mock data
  };

  return (
    <footer className="bg-black py-20 text-white">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 py-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Logo and Description */}
        <div>
          <Link to="/" className="text-white mb-5 block uppercase text-xl">
            Clothology Footer
          </Link>
          <p className="my-6 text-sm">
          ClothologyGlobal is your one-stop destination for trendy and affordable fashion. We offer a curated selection of apparel and accessories for men and women, designed to keep you stylish every season.
          </p>
        </div>

        {/* Company Section */}
        <div>
          <h5 className="text-gray-300 font-bold mb-3 text-sm">Company</h5>
          <ul>
            {[
              'About Us',
              "We're Hiring",
              'Terms & Conditions',
              'Privacy Policy',
              'Return/Refund Policy',
              'FAQs',
              'Blogs',
            ].map((item) => (
              <li key={item} className="mb-2">
                <Link to="/" className="text-sm hover:text-gray-400">
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Customer Service Section */}
        <div>
          <h5 className="text-gray-300 font-bold mb-3 text-sm">Customer Service</h5>
          <ul>
            {['Contact Us', 'Track Order', 'Return Order', 'Cancel Order'].map(
              (item) => (
                <li key={item} className="mb-2">
                  <Link to="/" className="text-sm hover:text-gray-400">
                    {item}
                  </Link>
                </li>
              )
            )}
          </ul>
        </div>

        {/* Social Media Section */}
        <div>
          <h5 className="text-gray-300 font-bold mb-3 text-sm">Connect With Us</h5>
          <ul className="flex space-x-6 items-center">
            <li className="flex items-center space-x-2">
              <a
                href="https://www.facebook.com/Clothologyg1/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm hover:text-gray-400"
              >
                <FaFacebook size={24} />
              </a>
              <span className="text-xs">
                {socialStats.facebook !== null ? `${socialStats.facebook} Likes` : 'Loading...'}
              </span>
            </li>
            <li className="flex items-center space-x-2">
              <a
                href="https://www.instagram.com/clothologyglobal/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm hover:text-gray-400"
              >
                <FaInstagram size={24} />
              </a>
              <span className="text-xs">
                {socialStats.instagram !== null ? `${socialStats.instagram} Followers` : 'Loading...'}
              </span>
            </li>
            <li className="flex items-center space-x-2">
              <a
                href="https://twitter.com/Clothologygloin"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm hover:text-gray-400"
              >
                <FaXTwitter size={24} />
              </a>
              <span className="text-xs">
                {socialStats.twitter !== null ? `${socialStats.twitter} Followers` : 'Loading...'}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
