// Breadcrumb.js
import React from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { FaAngleRight, FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Breadcrumb({ category, productName }) {
  return (
    <nav className="breadcrumb text-gray-600 text-sm max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 pb-12 pt-4">
      <ul className="flex space-x-2 items-center">
        <li>
          <Link to="/" className="hover:underline">
            Home
          </Link>
        </li>
        <BiChevronRight />
            {/* <FaAngleRight className='text-[22px] opacity-35'/> */}
        <li>
          <Link to="/products" className="hover:underline">
            {category || "Category"}
          </Link>
        </li>
        <BiChevronRight />
        <li className="text-gray-800 font-semibold">
          {productName || "Product Name"}
        </li>
      </ul>
    </nav>
  );
}

export default Breadcrumb;
