import React from 'react';
import { useParams } from 'react-router-dom';
import ProductPageBody from '../components/ProductPageBody';
import MainLayOut from '../layout/MainLayOut';
import MobileMainLayout from '../layout/MobileMainLayout';


function ProductPage({ showAll }) {
  const { categorySlug } = useParams();
  return(
    <>
    <div className='hidden md:block'>
      <MainLayOut>
          <ProductPageBody categorySlug={showAll ? null : categorySlug} />;
        </MainLayOut>
    </div>
    <div className='md:hidden'>
      <MobileMainLayout Products="35" title={showAll ? 'All Products' : categorySlug}>
          <ProductPageBody categorySlug={showAll ? null : categorySlug} />;
        </MobileMainLayout>
    </div>  
      
    </>
  ) 
}

export default ProductPage;
