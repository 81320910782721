import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MainLayOut from '../layout/MainLayOut';
import API_BASE_URL from './../global/apiConfig';
import axios from 'axios';

const Checkout = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('userToken');
  const [isLoading, setIsLoading] = useState(true);
  const [cartItems, setCartItems] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [shippingDetails, setShippingDetails] = useState({
    full_name: '',
    email: '',
    mobile_number: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    zip_code: '',
  });

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else {
      fetchCart();
      fetchShippingAddresses();
    }
  }, [token, navigate]);

  const fetchCart = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get_customer_cart`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCartItems(response.data.cart_items || []);
      console.log('response.data.cart_items', response.data.cart_items);
    } catch (error) {
      console.error('Error fetching cart:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchShippingAddresses = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get_customer_shipping_addresses`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setShippingAddresses(response.data.shipping_addresses || []);
    } catch (error) {
      console.error('Error fetching shipping addresses:', error.message);
    }
  };

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
    setShippingDetails(address);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails((prev) => ({ ...prev, [name]: value }));
  };

  const calculateSummary = () => {
    const totalMRP = cartItems.reduce(
      (acc, item) => acc + item.regular_price * item.quantity,
      0
    );
    const subtotal = cartItems.reduce(
      (acc, item) => acc + item.sale_price * item.quantity,
      0
    );
    return { totalMRP, subtotal, savings: totalMRP - subtotal };
  };

  const { totalMRP, savings, subtotal } = calculateSummary();

  const handleSubmit = async () => {
    // if (!shippingDetails.full_name || !shippingDetails.address_line_1 || !shippingDetails.city) {
    //   alert('Please fill in all required fields!');
    //   return;
    // }
    
    const shippingId = shippingDetails.id ;
    const orderPayload = {
      order_items: cartItems.map((item) => ({
        product_id: item.product_id,
        product_name: item.prod_name,
        quantity: item.quantity,
        price: item.sale_price,
        total_price: subtotal,
      })),
      shipping_address: shippingId,
      bill_amt: subtotal,
      discount_amt: 0,
      net_amt: subtotal,
      shipping_total: 0,
      pay_amt: subtotal,
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/create_order`, orderPayload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('create_order',response);
      alert('Order placed successfully!');
      navigate('/thank-you');
    } catch (error) {
      console.error('Error creating order:', error.message);
      alert('Failed to place order. Please try again.');
    }
  };


  return (
    <MainLayOut>
      <div className="checkout-page p-4 max-w-7xl mx-auto">
        {/* Breadcrumb */}
        <div className="breadcrumb mb-4 text-gray-500">
          <Link to="/">Home</Link> &gt; <span>checkout</span>
        </div>
        <hr className='mb-4'/>

        <h2 className="text-2xl font-semibold mb-6">Checkout</h2>
        <div className="flex justify-between flex-col md:flex-row gap-4">
          <div className='w-full md:w-1/2'>
        {/* Previous Addresses */}
        <div className="mb-6">
          <h4 className='mb-4'>Previous Addresses</h4>
          {shippingAddresses.length > 0 ? (
            <div className="flex flex-wrap gap-4">
              {shippingAddresses.map((address) => (
                <div
                  key={address.id}
                  className={`p-4 border rounded-lg cursor-pointer ${
                    selectedAddress?.id === address.id ? 'border-blue-500' : 'border-gray-300'
                  }`}
                  onClick={() => handleAddressSelect(address)}
                >
                  <div>{address.full_name}</div>
                  <div>{address.address_line_1}</div>
                  <div>
                    {address.city}, {address.state} - {address.zip_code}
                  </div>
                </div>
              ))}
              <Link className='border border-gray-400 rounded h-[15px] w-[15px] text-gray-500 flex items-center justify-center' to={"/myaccount/addresses"}>+</Link>
            </div>
          ) : (
            <Link to={"/myaccount/addresses"}>Add your shipping address</Link>
          )}
        </div>

        {/* Shipping Details Form */}
        <div className="mb-6">
          <h4>Shipping Details</h4>
          <form>
            <div>
              <label>Full Name</label>
              <input
                type="text"
                name="full_name"
                value={shippingDetails.full_name}
                onChange={handleInputChange}
                className="w-full border p-2 rounded"
              />
            </div>
            <div>
              <label>Address Line 1</label>
              <input
                type="text"
                name="address_line_1"
                value={shippingDetails.address_line_1}
                onChange={handleInputChange}
                className="w-full border p-2 rounded"
              />
            </div>
            <div>
              <label>City</label>
              <input
                type="text"
                name="city"
                value={shippingDetails.city}
                onChange={handleInputChange}
                className="w-full border p-2 rounded"
              />
            </div>
            {/* Additional fields... */}
          </form>
        </div>
        </div>
        {/* Order Summary */}
        <div className='w-full md:w-1/2 mt-6'>
        <div className="mb-6 p-4 border rounded-lg">
          <h4 className='font-medium mb-3'>Order Items</h4>
          <div className='flex flex-col gap-3'>
            {
              cartItems.map((item, index) => (
                <div key={index} className="flex items-center mb-4">
                  <div className='relative'>
                  <img src={item.primary_img} alt={item.prod_name} className="w-12 h-
                  csharp
                  12 object-cover rounded" />
                  {/* <span className='absolute w-4 h-4 rounded text-white bg-opacity-25 bg-black'>
                    {item.quantity}
                  </span>  */}
                  </div>
                 
                  <div className="ml-4">
                    <p className="text-sm font-medium text-gray-500">{item.prod_name}</p>
                    <p className="text-sm font-medium text-gray-500">Items: <span className='text-green-600'>{item.quantity}</span></p>
                  </div>
                  {/* Estimated delivery by 07 January 2025 */}
                  </div>
           )) }
          </div>
        </div>
        <div className="mb-6 p-4 border rounded-lg">
          <h4 className='font-medium'>Order Summary</h4>
          <div className='flex justify-between items-center'>
            <span className='text-gray-500 font-medium text-sm'>
              Total MRP (Incl. of GST): 
            </span>
          <span className='text-base'>
          {new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
            }).format(totalMRP)}
            </span>
          </div>
          <div className='text-gray-500 font-medium text-sm flex justify-between items-center my-3'>Bag Discount : <span className='text-green-600 text-base'> -
            {new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
            }).format(savings)}</span></div>
          <div className='text-gray-500 font-medium text-sm flex justify-between items-center'>Delivery Fee : <span className='text-green-600'>Free</span></div>
          <hr className='my-3'/>
          <div className='text-gray-800 font-medium flex justify-between items-center'>Subtotal:
            <span className='text-green-600'>
            {new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
            }).format(subtotal)}
            </span>
          </div>
        </div>

        {/* Submit Button */}
        <button onClick={handleSubmit} className="bg-black text-white px-4 py-2 rounded">
          Place Order
        </button>
        </div>
      </div>
      </div>
    </MainLayOut>
  );
};

export default Checkout;
