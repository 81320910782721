import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainLayOut from '../layout/MainLayOut';
import { FaCartPlus, FaHeart, FaRegHeart } from 'react-icons/fa';
import Breadcrumb from '../components/Breadcrumb';
import ProductSizeAndColor from '../components/ProductSizeGuide';
// import { useCart } from '../global/GlobalContext';
import { useGlobal } from '../global/GlobalContext';
import API_BASE_URL from '../global/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/ReactToastify.css';

function ProductDetails() {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const { addToWishlist, addToCart, token } = useGlobal();
  const [wishlist, setWishlist] = useState([]);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/get_slug_data/${slug}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status) {
          const selectedProduct = data.product_details;
          setProduct(selectedProduct);
          setSelectedImage(selectedProduct.primary_img); // Set the initial main image
        }
      })
      .catch((error) => console.error('Error fetching product:', error));
  }, [token, slug]);

  const handleAddToWishlist = (product) => {
    const isAlreadyInWishlist = wishlist.some((item) => item.id === product.id);
  
    if (isAlreadyInWishlist) {
      toast.info(`${product.prod_name} is already in your wishlist!`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      addToWishlist(product);
      toast.success(`${product.prod_name} added to your wishlist!`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  
  const handleAddToCart = (product) => {
    const isAlreadyInCart = cart.some((item) => item.id === product.id);
  
    if (isAlreadyInCart) {
      toast.info(`${product.prod_name} is already in your cart!`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      addToCart(product);
      toast.success(`${product.prod_name} added to your cart!`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // const handleAddToCart = async (product) => {
  //   const isAlreadyInCart = cart.some((item) => item.id === product.id);

  //   console.log('isAlreadyInCart', isAlreadyInCart);
  
  //   if (!isAlreadyInCart) {
  //     try {
  //       setCart([...cart, product]); 
  //       const response = await fetch(`${API_BASE_URL}/add_to_cart`, {
  //         method: 'POST',
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'Content-Type': 'application/json',
  //           Accept: 'application/json',
  //         },
  //         body: JSON.stringify({
  //           product_id: product.id,
  //           prod_variation_id: null,
  //           quantity: 1,
  //           price: product.sale_price,
  //         }),
  //       });
  
  //       const data = await response.json();
  //       console.log('cart testing', data);
  
  //       if (data.status) {
  //         console.log('Product added to cart');
  //       } else {
  //         console.error('Failed to add to cart:', data.error_message);
  //         setCart(cart.filter((item) => item.id !== product.id));
  //       }
  //     } catch (error) {
  //       console.error('Error adding to cart:', error);
  //       setCart(cart.filter((item) => item.id !== product.id));
  //     }
  //   } else {
  //     setCart(cart.filter((item) => item.id !== product.id));
  //   }
  // };
  if (!product) {
    return <p className="text-center text-gray-500 my-10">Loading product details...</p>;
  }

  

  return (
    <MainLayOut>

        <Breadcrumb
          category="Products"
          productName={product.prod_name} 
        />
      <div className="single-product-page max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 pb-12">
        <div className="flex flex-col sm:flex-row items-start gap-6 animate-fadeIn">
          {/* Thumbnails */}
          <div className="flex sm:flex-col gap-4 sm:w-1/12 w-full">
          {[product.primary_img, product.secondary_img, ...(product.product_image_list || []).map(imgObj => imgObj.prod_img_url)].map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`${product.prod_name} thumbnail ${index + 1}`}
              className={`w-24 h-28 object-cover cursor-pointer transition-transform transform ${
                selectedImage === img ? 'scale-105 border-2 border-black' : 'hover:scale-105'
              }`}
              onClick={() => setSelectedImage(img)}
            />
          ))}

          </div>

          {/* Main Image Display */}
          <div className="w-full sm:w-1/2 overflow-hidden shadow-lg">
            <img
              src={selectedImage}
              alt={product.prod_name}
              className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
            />
          </div>

          {/* Product Information */}
          <div className="w-full sm:w-1/2 flex flex-col space-y-6">
          {/* <h1 className=" text-black font-bold">{product.brand_details.brand_name}</h1> */}
            <h1 className=" text-black font-bold">{product.prod_name}</h1>
            <p className=" text-gray-600 text-sm leading-relaxed">
              {product.prod_desc || 'No description available'}
            </p>

            <div className="flex items-center space-x-4">
              <p className="font-bold text-sm text-gray-800">
                ₹ {product.sale_price || product.regular_price}
              </p>
              {product.sale_price && (
                <p className="text-sm text-gray-500 line-through">₹ {product.regular_price}</p>
              )}
            </div>



            <ProductSizeAndColor />

            {/* Add to Cart and Wishlist Button */}
            <div className="flex items-center space-x-4 flex-wrap">
              <button
                onClick={() => handleAddToCart(product)}
                className="text-white bg-black px-5 py-2 rounded-sm text-sm font-semibold transform transition-transform duration-300 hover:scale-105 hover:shadow-lg"
              >
              <FaCartPlus />
              <span className='hidden md:block'>Add to Cart</span>
              </button>
              <button onClick={() => handleAddToWishlist(product)} className="text-black border border-black text-sm px-5 m-0 py-2 rounded-sm font-semibold transform transition-transform duration-300 hover:scale-105 hover:shadow-lg">
                {wishlist.some((item) => item.id === product.id) ? <FaHeart /> : <FaRegHeart />} 
                <span className='hidden md:block'>Add to whishlist</span>
              </button>
            </div>
            <ToastContainer/>

            {/* Product Highlights */}
            <div className="mt-6">
              <h2 className="font-bold text-lg mb-2">Key Highlights</h2>
              <ul className="list-disc ml-4 space-y-1 text-gray-700">
                <li>Fit: {product.fit || 'Regular Fit'}</li>
                <li>Material: {product.material || 'Not specified'}</li>
                <li>Sleeve: {product.sleeve || 'Full Sleeve'}</li>
                <li>Neck: {product.neck || 'Round Neck'}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </MainLayOut>
  );
}

export default ProductDetails;
