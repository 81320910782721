import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactOwlCarousel from 'react-owl-carousel';
import { useGlobal } from '../../global/GlobalContext';
import axios from 'axios';
import API_BASE_URL from '../../global/apiConfig';

const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 2000,
    margin: 10,
    nav: false,
    autoWidth: true,
    items: 3,
};

const SeparateCatMenMobile = ({ slug }) => {
    const [getCategory, setGetCategory] = useState([]);
    const { token } = useGlobal();
    const [loading, setLoading] = useState(true);

    const fetchCategory = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/get_slug_data/${slug}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const categoryData = response.data;
            setGetCategory(categoryData.category_data.child_categories);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching category data:', error);
            setLoading(false);
        }
    }, [slug, token]);

    useEffect(() => {
        fetchCategory();
    }, [fetchCategory]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-black text-lg font-semibold">Loading Men Products...</div>
            </div>
        );
    }

    if (!loading && getCategory.length === 0) {
        return (
            <div className="text-center text-gray-500">
                No categories available. Please try again later.
            </div>
        );
    }

    return (
        <div>
            <ReactOwlCarousel {...options} className="owl-theme inner_home owl_mobile">
                {getCategory.map((bannercat) => (
                    <div className="item px-1" key={bannercat.id}>
                        <Link to={`../${bannercat.slug}`} className="flex flex-col justify-end relative w-[375px] h-[350px] rounded-lg">
                            <img
                                className="w-full absolute h-full object-cover top-0 left-0 rounded-lg"
                                src={bannercat.cat_img}
                                alt={bannercat.category_name || "Category Image"}
                            />
                        </Link>
                    </div>
                ))}
            </ReactOwlCarousel>
        </div>
    );
};

export default SeparateCatMenMobile;
