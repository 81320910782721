import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ProductCard from "../components/ProductCard";
import API_BASE_URL from "../global/apiConfig";
import { useGlobal } from "../global/GlobalContext";
import MainLayOut from "../layout/MainLayOut";
import { FaSearch } from "react-icons/fa";


const SearchPage = () => {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {token} = useGlobal();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("q");
    if (searchQuery) {
      setQuery(searchQuery);
      fetchSearchResults(searchQuery);
    }
  }, [location]);

  const fetchSearchResults = async (searchQuery) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/get_active_products`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        }
      });
      if (response.data.status) {
        const filteredProducts = response.data.products.filter((product) =>
          product.prod_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setSearchResults(filteredProducts);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/search?q=${query.trim()}`);
      fetchSearchResults(query.trim());
    }
  };

  return (
    <MainLayOut>
    <div className="search-page max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-12">
      {/* Search Bar */}
      <form onSubmit={handleSearch} className="mb-6 flex items-center">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search for products..."
          className="w-full border border-gray-300 rounded-md px-4 py-2"
        />
        <button
          type="submit"
          className="ml-2 bg-black text-white px-4 py-3 rounded-md"
        >
          <FaSearch />
        </button>
      </form>

      {/* Search Results */}
      {loading ? (
        <div className="text-center py-8">Loading...</div>
      ) : searchResults.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {searchResults.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      ) : (
        <div className="text-center py-8">No results found for "{query}"</div>
      )}
    </div>
    </MainLayOut>
  );
};

export default SearchPage;
