import React from "react";
import MainLayOut from '../layout/MainLayOut';
import HeroSection from '../components/HeroSection';
import HomeBody from '../components/HomeBody';
import { Link } from 'react-router-dom';
import MobileHomeBody from "./mobile/MobileHomeBody";



const Home = () =>{
    return(
        <MainLayOut>
            <div className="justify-center p-4 md:flex items-center bg-gray-200 gap-10 extra_need hidden">
                <Link>CLOO SALE</Link>
                <Link>SUPERCOMBED TEES</Link>
                <Link>OVERSIZED TEES</Link>
                <Link>CLOTHOLOGY GLOBAL SIGNATURE</Link>
                <Link>ACCESSORIES</Link>
            </div>

            <div className="justify-center p-4 flex items-center gap-10 extra_need md:hidden">
                <Link className="text-center text-xs" to={'/offer'}>

                    <img src="images/mobile/offer.gif" className="w-14 h-14 p-1 border-4 border-yellow-300 rounded-full bg-white mb-2" />
                    
                    Offer! <span className="bg-green-400 p-1 border rounded-md">New</span>
                </Link>
                <Link className="text-center" to={'/mens-home'}>
                    <img src="images/mobile/boy.png" className="w-14 h-14 p-2 rounded-lg bg-gray-300" />
                    <span className="w-14 block text-xs" >Men</span>
                </Link>
                <Link className="text-center" to={'/womens-home'}>
                    <img src="images/mobile/girl.png" className="w-14 h-14 p-2 rounded-lg bg-gray-300" />
                    <span className="w-14 block text-xs" >Women</span>
                    
                </Link>
                {/* <Link to={'/winter-home'}>
                    <img src="images/snowman.png" className="w-10 h-10" />
                    Winterwear
                </Link> */}
                {/* <Link>
                    <img src="images/sneakers.png" className="w-10 h-10" />
                    Sneakers
                </Link> */}
                <Link className="text-center" to={'/accessories'}>
                    <img src="images/mobile/bags.png" className="w-14 h-14 p-2 rounded-lg bg-gray-300" />
                    <span className="w-14 block text-xs" >Accessories</span>             
                </Link>
                <Link className="text-center" to={'/skincare'}>
                    <img src="images/mobile/skincare.png" className="w-14 h-14 p-2 rounded-lg bg-gray-300" />
                    <span className="w-14 block text-xs" >Skincare</span>  
                </Link>
                <Link className="text-center" to={'/footwear'}>
                    <img src="images/mobile/shoes.png" className="w-14 h-14 p-2 rounded-lg bg-gray-300" />
                    <span className="w-14 block text-xs" >Footwear</span>  
                </Link>
                <Link className="text-center" to={'/custom'}>
                    <img src="images/mobile/pencil.png" className="w-14 h-14 p-2 rounded-lg bg-gray-300" />
                    <span className="w-14 block text-xs" >Custom</span>  
                </Link>
            </div>
            <div className="hidden md:block">
                <HeroSection />
            </div>
            <div className="md:hidden">
              <MobileHomeBody />
            </div>

            <div className="hidden md:block">
             <HomeBody/> 
            </div>
             
                                  
        </MainLayOut>
    )
}

export default Home;