import React, { useCallback, useEffect, useState } from 'react';
import API_BASE_URL from '../../global/apiConfig';
import { useGlobal } from '../../global/GlobalContext';

const AllCatMenMobile = ({slug}) => {
    const [trend, setTrend] = useState([]);
    const { token } = useGlobal();

    const fetchCat = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/get_slug_data/${slug}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setTrend(data.category_data.child_categories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    }, [token]);

    useEffect(() => {
        fetchCat();
    }, [fetchCat]);

    // Function to split array into chunks of two
    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    const chunks = chunkArray(trend, 2); // Split into chunks of 2

    return (
        <div className='my-4'>
            <h2 className='font-semibold text-sm mb-3'>Trending Categories {slug}</h2>
            <div className="flex gap-2 overflow-scroll">
                {chunks.map((chunk, index) => (
                    <div key={index} className="flex flex-col gap-2">
                        {chunk.map((item) => (
                            <div
                                key={item.id}
                                className="rounded-lg w-[150px] h-[175px] relative need_below_overley"
                            >
                                <img
                                src={item.cat_img}
                                alt={item.category_name}
                                className='w-full h-full object-cover rounded-xl'
                                />
                                <div className="text-center text-xs text-white font-semibold absolute bottom-2 w-full">
                                    {item.category_name}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AllCatMenMobile;
