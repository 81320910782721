import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaRegHeart, FaHeart, FaFilter } from "react-icons/fa";
import axios from "axios";
import { useGlobal } from "../global/GlobalContext";
import API_BASE_URL from "../global/apiConfig";
import WishlistButton from "./WishlistButton";
import NotFound from "../pages/NotFound";
import { FaArrowRight } from "react-icons/fa6";
import {BiChevronRight } from "react-icons/bi";

function ProductPageBody({ categorySlug }) {
  const { token, addToWishlist, removeFromWishlist, wishlist, setWishlist } = useGlobal();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [filters, setFilters] = useState({ size: [], brand: [], color: [] });
  const [loading, setLoading] = useState(false);
  const [filterMenu, setFilterMenu] = useState(false);
  const [sizeMenu, setSizeMenu] = useState(false);
  const [brandsMenu, setBrandsMenu] = useState(false);
  const [colorsMenu, setColorsMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/get_active_products`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (response.data.status) {
          const allProducts = response.data.products;
  
          const filteredProducts = allProducts.filter(
            (product) => product.brand_details && product.brand_details.brand_name
          );
  
          const isValidCategory = categorySlug
            ? filteredProducts.filter((product) =>
                product.product_categories.some(
                  (category) => category.slug === categorySlug
                )
              )
            : filteredProducts;
  
          if (isValidCategory.length > 0) {
            setProducts(isValidCategory);
          } else if (categorySlug) {
            navigate('/404');
            console.warn("No products found for the given category slug.");
            setProducts([]);
          } else {
            setProducts(filteredProducts);
          }
  
          const uniqueSizes = [
            ...new Set(
              filteredProducts.flatMap((product) =>
                product.product_variations?.map((variation) => variation.size)
              )
            ),
          ];
          const filteredSizes = uniqueSizes.filter(size => size !== null);
          if (filteredSizes.length > 0) {
            setSizes(filteredSizes);
          }

          const uniqueBrands = [
            ...new Set(filteredProducts.map((product) => product.brand_details?.brand_name)),
          ];
          const filteredBrands = uniqueBrands.filter(size => size !== null);
          if (filteredBrands.length > 0) {
            setBrands(filteredBrands);
          }

          const uniqueColors = [
            ...new Set(filteredProducts.flatMap((product) => 
              product.product_variations?.map((variation) => variation.color)
            )),
          ];
          const filteredColors = uniqueColors.filter(size => size !== null);
          if (filteredColors.length > 0) {
            setColors(filteredColors);
          }
        } else {
          console.error('Error: Invalid response status from API');
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (categorySlug !== undefined) {
      fetchProducts();
    }
  }, [categorySlug]);
  

  const toggleFilter = (filterType, value) => {
    setFilters((prevFilters) => {
      const currentValues = prevFilters[filterType] || [];
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((item) => item !== value)
        : [...currentValues, value];

      return { ...prevFilters, [filterType]: updatedValues };
    });
  };

  useEffect(() => {
    const filtered = products.filter((product) => {
      const matchesSize =
        !filters.size.length || // If no size filter selected
        product.product_variations.some((v) => filters.size.includes(v.size));
      const matchesBrand =
        !filters.brand.length || filters.brand.includes(product.brand_details.brand_name);
      const matchesColor =
      !filters.color.length ||
      product.product_variations.some((v) => filters.color.includes(v.color));

      return matchesSize && matchesBrand && matchesColor;
    });

    setFilteredProducts(filtered);
  }, [filters, products]);

  const handleFilterChange = (filterType, value) => {
    toggleFilter(filterType, value);

    const newParams = new URLSearchParams(location.search);
    newParams.set(filterType, value);
    navigate({
      pathname: location.pathname,
      search: newParams.toString(),
    });
  };
  const formattedCategory = categorySlug
    ? categorySlug.replace(/-/g, " ").replace(/\bt shirts\b/i, "t-shirts")
    : "All Products";

  // if (loading) {
  //   return <div style={{height: '100vh', background: '#fff0', backdropFilter: '12px', 
  //   position:'absolute', zIndex:'1000',top:'0',left:'0',width:'100%', display:'flex', justifyContent:'center',alignItems:'center' }}>
  //         <div className="w-10">
  //           <img className="w-full" src="/logoCloth.jpg" />
  //         </div>
  //     </div>;
  // }
  if (!products) {
    return <NotFound />;
  }

  return (
    <div className="product-page-body max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 mb-12 mt-4">
      {/* Breadcrumb */}
      <div className="breadcrumb mb-5 text-gray-500 flex items-center gap-4">
        <Link className="text-sm" to="/">Home</Link> <BiChevronRight />  <span className="uppercase text-sm">{formattedCategory}</span>
      </div>

      <div className="flex justify-between flex-col md:flex-row">
        {/* Filter Sidebar */}
        <div className="filter sticky w-1/5 top-0 h-screen p-4 border-gray-200 hidden md:block">
          <div className="filterTitle mb-6 font-bold text-sm text-gray-400">Filters</div>
          <div className="filterOptions flex flex-col gap-4">
            {/* Size Filter */}
            <div>
              <button className="font-bold mb-3 text-gray-700">Sizes</button>
              <div className="flex ms-1 flex-col">
              {sizes.map((size, index) => (
                <button
                  key={index}
                  className={`filterOption text-sm capitalize px-3 py-1 rounded-full text-start mb-2 ${
                    filters.size.includes(size) ? "bg-gray-800 text-white" : "bg-gray-200"
                  }`}
                  onClick={() => toggleFilter("size", size)}
                >
                  {size}
                </button>
              ))}
              </div>
            </div>

            {/* Brand Filter */}
            <div>
              <h3 className="font-bold mb-3 text-gray-700">Brands</h3>
              <div className="flex ms-1 flex-col">
                {brands.map((brand, index) => (
                  <button
                    key={index}
                    className={`filterOption text-sm capitalize px-3 py-1 rounded-full text-start mb-2 ${
                      filters.brand.includes(brand) ? "bg-gray-800 text-white" : "bg-gray-200"
                    }`}
                    onClick={() => toggleFilter("brand", brand)}
                  >
                    {brand}
                  </button>
                ))}
              </div>
            </div>

            {/* Color Filter */}
            <div>
              <h3 className="font-bold mb-3 text-gray-700">Colors</h3>
              <div className="flex ms-1 flex-col">
              {colors.map((color, index) => (
                <button
                  key={index}
                  className={`filterOption text-sm capitalize px-3 py-1 rounded-full text-start mb-2 ${
                    filters.color.includes(color) ? "bg-gray-800 text-white" : "bg-gray-200"
                  }`}
                  onClick={() => toggleFilter("color", color)}
                >
                  {color}
                </button>
              ))}
              </div>
            </div>
          </div>
        </div>

        {/* mobile */}
        <div className="md:hidden mobile_menu flex items-start mb-5">
          <button
            className="text-black mt-1"
            onClick={() => setFilterMenu(!filterMenu)}
          >
            <FaFilter />
          </button>
          {filterMenu && (
            <div className="filter sticky w-full top-0 px-4 border-gray-200">
              <div className="filterOptions flex flex-row gap-4">
                {/* Size Filter */}
                <div>
                  <button
                    className="text-black mt-1 border border-black rounded-full px-2"
                    onClick={() => setSizeMenu(!sizeMenu)}
                  >
                    Sizes
                  </button>
                  {sizeMenu && (
                    <div className="flex flex-col">
                      {sizes.map((size, index) => (
                        <button
                          key={index}
                          className={`filterOption text-sm capitalize px-3 py-1 rounded-full ${
                            filters.size.includes(size) ? "bg-gray-800 text-white" : "bg-gray-200"
                          }`}
                          onClick={() => toggleFilter("size", size)}
                        >
                          {size}
                        </button>
                      ))}
                    </div>
                  )}
                </div>

                {/* Brand Filter */}
                <div>
                  <button
                    className="text-black mt-1 border border-black rounded-full px-2"
                    onClick={() => setBrandsMenu(!brandsMenu)}
                  >
                    Brands
                  </button>
                  {brandsMenu && (
                    <div className="flex flex-col">
                      {brands.map((brand, index) => (
                        <button
                          key={index}
                          className={`filterOption text-sm capitalize px-3 py-1 rounded-full ${
                            filters.brand.includes(brand) ? "bg-gray-800 text-white" : "bg-gray-200"
                          }`}
                          onClick={() => toggleFilter("brand", brand)}
                        >
                          {brand}
                        </button>
                      ))}
                    </div>
                  )}
                </div>

                {/* Color Filter */}
                <div>
                  <button
                    className="text-black mt-1 border border-black rounded-full px-2"
                    onClick={() => setColorsMenu(!colorsMenu)}
                  >
                    Colors
                  </button>
                  {colorsMenu && (
                    <div className="flex flex-col">
                      {colors.map((color, index) => (
                        <button
                          key={index}
                          className={`filterOption text-sm capitalize px-3 py-1 rounded-full ${
                            filters.color.includes(color) ? "bg-gray-800 text-white" : "bg-gray-200"
                          }`}
                          onClick={() => toggleFilter("color", color)}
                        >
                          {color}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Product Listing */}
        
        <div className="all_products w-full md:w-4/5">
          <div className="flex gap-3 text-xl mb-5 md:mb-10 items-baseline">
            <h1 className="font-medium text-gray-800">Clothes for <span className="capitalize">{formattedCategory}</span></h1>
            <span className="text-gray-400 text-base"> ({filteredProducts.length} Products)</span>
          </div>
          <div className="flex flex-wrap gap-4">
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              className="item w-[47%] h-[400px] md:w-[30%] bg-white overflow-hidden relative md:h-[550px]"
            >
              <Link to={`/p/${product.slug}`} className="relative w-full h-[70%]">
                <img
                  src={product.primary_img}
                  alt={product.prod_name}
                  className="w-full h-full object-cover transition-opacity duration-300 ease-in-out"
                />
              </Link>

              <div className="p-4 absolute bottom-0 w-full bg-white bg-opacity-90">
                <div className="flex justify-between items-center">
                  <h3 className="text-sm text-gray-900 w-4/5 font-semibold">
                    {product.brand_details.brand_name}
                  </h3>
                  <WishlistButton
                    product={product}
                    wishlist={wishlist}
                    setWishlist={setWishlist}
                    token={token}
                    API_BASE_URL={API_BASE_URL}
                  />
                </div>
                <h2 className="text-sm text-gray-500 truncate w-4/5 font-semibold">
                    {product.prod_name}
                  </h2>

                <div className="flex gap-2 items-end">
                  <p className="text-black text-sm font-bold">₹ {product.sale_price}</p>
                  <p className="text-gray-500 line-through text-sm">
                    ₹ {product.regular_price}
                  </p>
                  <p className="text_hightlight text-xs">
                    {(
                      ((product.regular_price - product.sale_price) /
                        product.regular_price) *
                      100
                    ).toFixed(2)}{" "}
                    % OFF
                  </p>
                </div>
              </div>
            </div>
          ))}
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default ProductPageBody;
