import React from 'react'
import { BiCategory, BiHome, BiLogoInternetExplorer, BiSolidUserAccount } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const BottomFixedNav = () => {
  return (
    <div>
      <div className='fixed bottom-0 z-50 w-full p-2 bg-white'>
        <div className='flex justify-between'>
            <Link className='flex items-center flex-col gap-1' to={'/'}>
            <BiHome />
            <span className="text-sm font-bold">
                Home
            </span>
            </Link>
            <Link className='flex items-center flex-col gap-1' to={'products'}>
            <BiCategory />
                <span className="text-sm font-bold">Categories</span>
            </Link>
            <Link className='flex items-center flex-col gap-1' to={'/'}>
                <BiLogoInternetExplorer />
                <span className="text-sm font-bold">Explore</span>
            </Link>
            <Link className='flex items-center flex-col gap-1' to={'/myaccount'}>
                <BiSolidUserAccount />
                <span className="text-sm font-bold">Profile</span>    
            </Link>
        </div>
      </div>
    </div>
  )
}

export default BottomFixedNav
