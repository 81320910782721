import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation  } from 'react-router-dom';
import { FaMale, FaFemale, FaTags, FaStar } from 'react-icons/fa';
import axios from 'axios';
import API_BASE_URL from '../../global/apiConfig';
import { useGlobal } from '../../global/GlobalContext';



function NavMenus() {
  const [isMouseMoved, setIsMouseMoved] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const location = useLocation();

const[ menTopwear, setMenTopwear ] = useState([]);
const[ menBottomwear, setMenBottomwear ] = useState([]);
const[ menWinterwear, setMenWinterwear ] = useState([]);
const[ menFootwear, setMenFootwear ] = useState([]);
const[ womenTopwear, setWomenTopwear ] = useState([]);
const[ womenBottomwear, setWomenBottomwear ] = useState([]);
const[ womenWinterwear, setWomenWinterwear ] = useState([]);
const[ womenFootwear, setWomenFootwear ] = useState([]);
const {token} = useGlobal();
const navigate = useNavigate();

const fetchMenTopwear = useCallback( async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get_slug_data/men`, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    );
    const categoryData = response.data.category_data; 
    console.log('categoryData', categoryData);

      if (categoryData) {
        const fetchtopwear = categoryData.child_categories.find((category) => 
          category.category_name === "Men's Topwear"
        );
        if (fetchtopwear) {
          setMenTopwear(fetchtopwear.child_categories);
          }
      }
      if (categoryData) {
            const fetchbottomwear = categoryData.child_categories.find((category) => 
              category.category_name === "Men's Bottomwear"
            );
      if (fetchbottomwear) {
        setMenBottomwear(fetchbottomwear.child_categories);
        }
    }
    if (categoryData) {
      const fetchwinterwear = categoryData.child_categories.find((category) => 
        category.category_name === "Winterwear Men"
      );
if (fetchwinterwear) {
  setMenWinterwear(fetchwinterwear.child_categories);
  }
}
if (categoryData) {
  const fetchfootwear = categoryData.child_categories.find((category) => 
    category.category_name === "Men's Footwear"
  );
if (fetchfootwear) {
  setMenFootwear(fetchfootwear.child_categories);
}
}
  } catch (error) {
    console.error('Error fetching Men\'s Topwear:', error);
  }
},[]);
const fetchWomenTopwear = useCallback(async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get_slug_data/women`, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    );
    const categoryData = response.data.category_data; 
    console.log('categoryData', categoryData);

      if (categoryData) {
        const fetchtopwear = categoryData.child_categories.find((category) => 
          category.category_name === "Women's Topwear"
        );
        console.log('fetchtopwearwomen',fetchtopwear);
        if (fetchtopwear) {
          setWomenTopwear(fetchtopwear.child_categories);
          }
      }
      if (categoryData) {
            const fetchbottomwear = categoryData.child_categories.find((category) => 
              category.category_name === "Women's Bottomwear"
            );
      if (fetchbottomwear) {
        setWomenBottomwear(fetchbottomwear.child_categories);
        }
    }
    if (categoryData) {
      const fetchwinterwear = categoryData.child_categories.find((category) => 
        category.category_name === "Women's Winterwear"
      );
if (fetchwinterwear) {
  setWomenWinterwear(fetchwinterwear.child_categories);
  }
}
if (categoryData) {
  const fetchfootwear = categoryData.child_categories.find((category) => 
    category.category_name === "Women's Footwear"
  );
if (fetchfootwear) {
  setWomenFootwear(fetchfootwear.child_categories);
}
}
  } catch (error) {
    console.error('Error fetching Women\'s Topwear:', error);
  }
}, []);
useEffect(() => {
  fetchMenTopwear();
  fetchWomenTopwear();
},[fetchMenTopwear, fetchWomenTopwear]);
// Track mouse movement and enable hover effect after mouse move
useEffect(() => {
  // Only reset isMouseMoved when location changes (page navigation)
  setIsMouseMoved(false);
  setIsInitialLoad(true);

  const handleMouseMove = () => {
    setIsMouseMoved(true); // Enable hover when mouse moves
    setIsInitialLoad(false); // After first mouse move, stop considering it initial load
    window.removeEventListener('mousemove', handleMouseMove); // Clean up the event listener
  };

  // Add mousemove listener to detect when the user moves the mouse
  window.addEventListener('mousemove', handleMouseMove);

  return () => {
    window.removeEventListener('mousemove', handleMouseMove); // Cleanup on component unmount
  };
}, [location]); 

  return (
    <>
      <nav className="md:ml-10 ml-0 flex items-center space-x-8">
        <div className='group'>
          <div className='block md:hidden'>
            <img src="images/mobile/boy.png" className="w-7 h-7" />
          </div>
              <Link to="/men" className="text-gray-900 hover:text-black pb-[25px] text-sm md:text-base capitalize md:uppercase">
                Men
              </Link>
              <span
                className={`absolute hidden ${isMouseMoved && !isInitialLoad ? 'group-hover:flex' : ''} group-hover:top-[50px] group-hover:opacity-100 transition 
                            duration-1000 ease-in-out gap-5 bg-white p-12 text-nowrap left-[7%] right-[7%] top-[95px] opacity-0`}
              >
                        <ul>
                              <li>
                                <h4 className='my-3 font-medium'>Topwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  {menTopwear.map((item) => (
                                    <li key={item.id}>
                                      <Link to={`/${item.slug}`}>{item.category_name}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                        </ul>
                        <ul>
                              <li><h4 className='my-3 font-medium'>Bottomwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                {menBottomwear.map((item) => (
                                    <li key={item.id}>
                                      <Link to={`/${item.slug}`}>{item.category_name}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                        </ul>
                        <ul>
                              <li><h4 className='my-3 font-medium'>Winterwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                {menWinterwear.map((item) => (
                                    <li key={item.id}>
                                      <Link to={`/${item.slug}`}>{item.category_name}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                              <li><h4 className='my-3 font-medium'>Footwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                {menFootwear.map((item) => (
                                    <li key={item.id}>
                                      <Link to={`/${item.slug}`}>{item.category_name}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                        </ul>
                        {/* <ul>
                              <li><h4 className='my-3 font-medium'>Plus Size</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>T-shirts</li>
                                  <li>Shirts</li>
                                  <li>Jackets</li>
                                  <li>Sweatshirts & Hoodies</li>
                                  <li>Bottomwear</li>
                                  <li>Jeans</li>
                                </ul>
                              </li>
                              <li><h4 className='my-3 font-medium'>Innerwear & Loungewear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>Vests</li>
                                  <li>Pajamas</li>
                                  <li>Boxers</li>
                                </ul>
                              </li>
                        </ul> */}
                    </span>                   
                </div>
                <div className='group'>
                <div className='block md:hidden'>
                  <img src="images/mobile/girl.png" className="w-7 h-7" />
                </div>
                
                <Link to="/women" className="text-gray-900 hover:text-gray-700 pb-[25px] text-sm md:text-base capitalize md:uppercase">
                   Women
                </Link>
                <span
                className={`absolute hidden ${isMouseMoved && !isInitialLoad ? 'group-hover:flex' : ''} group-hover:top-[50px] group-hover:opacity-100 transition 
                            duration-1000 ease-in-out gap-5 bg-white p-12 text-nowrap left-[7%] right-[7%] top-[95px] opacity-0`}
              >
                      <ul>
                              <li>
                                <h4 className='my-3 font-medium'>Topwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  {womenTopwear.map((item) => (
                                    <li key={item.id}>
                                      <Link to={`/${item.slug}`}>{item.category_name}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                        </ul>
                        <ul>
                              <li><h4 className='my-3 font-medium'>Bottomwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                {womenBottomwear.map((item) => (
                                    <li key={item.id}>
                                      <Link to={`/${item.slug}`}>{item.category_name}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                        </ul>
                        <ul>
                              <li><h4 className='my-3 font-medium'>Winterwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                {womenWinterwear.map((item) => (
                                    <li key={item.id}>
                                      <Link to={`/${item.slug}`}>{item.category_name}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                              <li><h4 className='my-3 font-medium'>Footwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                {womenFootwear.map((item) => (
                                    <li key={item.id}>
                                      <Link to={`/${item.slug}`}>{item.category_name}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                        </ul>
                        {/* <ul>
                              <li><h4 className='my-3 font-medium'>Plus Size</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>T-shirts</li>
                                  <li>Shirts</li>
                                  <li>Jackets</li>
                                  <li>Sweatshirts & Hoodies</li>
                                  <li>Bottomwear</li>
                                  <li>Jeans</li>
                                </ul>
                              </li>
                              <li><h4 className='my-3 font-medium'>Innerwear & Loungewear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>Vests</li>
                                  <li>Pajamas</li>
                                  <li>Boxers</li>
                                </ul>
                              </li>
                        </ul> */}
                      </span>    
                </div>

              </nav>
    </>
  )
}

export default NavMenus
