import React, { useCallback, useEffect, useState } from 'react';
import MobileMainLayout from '../../layout/MobileMainLayout';
import { Link } from 'react-router-dom';
import CatWomenMobile from '../../components/mobileComponents/CatWomenMobileBanner';



const WomensHome = () => {


  return (
    <MobileMainLayout title="Women's Fashion">
        <CatWomenMobile />
        
      
    </MobileMainLayout>
  );
};

export default WomensHome;
