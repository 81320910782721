import React, { useEffect, useState } from 'react';
import { useGlobal } from '../../global/GlobalContext';
import API_BASE_URL from '../../global/apiConfig';

const OverView = () => {
  const { token } = useGlobal();
  const [orders, setOrders] = useState([]);
  const [limitOrders, setLimitOrders] = useState([]);
  const [customers, setCustomers] = useState([]);

useEffect(() =>{
  fetch(`${API_BASE_URL}/get_my_orders`, {
    headers: {
      Authorization: `Bearer ${token}`
      }
  })
  .then(response => response.json())
  .then((data) => {
    console.log('data.orders.customer',data.orders[0].customer);
      if(data.status){
        setOrders(data);
        setLimitOrders(data.orders.slice(0, 5));
        setCustomers(data.orders[0].customer);
      }
    })
},[])


  return (
    <div className="bg-gray-100 min-h-screen p-6">

      {/* mobile start */}

      {/* <div className='md:hidden'>
        <div className="max-w-5xl mx-auto hidden md:block">
        {orders.map((customer) => (
          <div key={customer.id} className="flex items-center justify-between bg-white p-6 rounded-lg shadow-lg mb-6">
          <div>
            <p className="text-black">{customer[0].customer.first_name}</p>
            <p className="text-gray-600"></p>
          </div>
          <img
            src="https://via.placeholder.com/80"
            alt="Profile Avatar"
            className="w-20 h-20 rounded-full border-2 border-black"
          />
        </div>
        ))}
        


        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

          <div className="bg-white p-4 rounded-lg shadow-lg text-center">
            <h2 className="text-lg font-semibold text-gray-700">Total Orders</h2>
            <p className="text-xl font-bold text-black">{orders.length}</p>
          </div>

        </div>

        <div className="mt-8">
          <h3 className="text-base font-semibold text-gray-800 mb-4">Recent Activity</h3>
          <ul className="bg-white p-6 rounded-lg shadow-lg">
            {limitOrders.map((activity) => (
              <li
                key={activity.id}
                className="flex flex-col py-4 border-b border-gray-200 last:border-0"
              >
                {activity.order_items && activity.order_items.length > 0 ? (
                  activity.order_items.map((item, index) => (
                    <div key={index} className="mb-2">
                      <span className="text-gray-600">{item.product_name || 'N/A'}</span>
                      <br />
                      <span className="text-gray-400 text-sm">
                        {item.updated_at
                          ? new Date(item.updated_at).toLocaleString()
                          : 'No update info'}
                      </span>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400">No items found</p>
                )}

              </li>
            ))}
          </ul>
        </div>
      </div>
      </div> */}

      {/* mobile end */}
      <div className="max-w-5xl mx-auto hidden md:block">
        {/* Profile Header */}
        <div className="flex items-center justify-between bg-white p-6 rounded-lg shadow-lg mb-6">
          <div>
            <h1 className="text-xl font-bold text-gray-800">Welcome Back!</h1>
            <p className="text-gray-600">Here's your profile overview</p>
          </div>
          <img
            src="https://via.placeholder.com/80"
            alt="Profile Avatar"
            className="w-20 h-20 rounded-full border-2 border-black"
          />
        </div>

        {/* Overview Stats */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Total Orders */}
          <div className="bg-white p-4 rounded-lg shadow-lg text-center">
            <h2 className="text-lg font-semibold text-gray-700">Total Orders</h2>
            <p className="text-xl font-bold text-black">{orders.length}</p>
          </div>
          {/* Total Spent */}
          {/* <div className="bg-white p-4 rounded-lg shadow-lg text-center">
            <h2 className="text-lg font-semibold text-gray-700">Total Spent</h2>
            <p className="text-xl font-bold text-black">0</p>
          </div> */}
          {/* Favorite Category */}
          {/* <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-semibold text-gray-700">
              Favorite Category
            </h2>
            <p className="text-2xl font-medium text-gray-800">{stats.favoriteCategory}</p>
          </div> */}
        </div>

        {/* Recent Activity */}
        <div className="mt-8">
          <h3 className="text-base font-semibold text-gray-800 mb-4">Recent Activity</h3>
          <ul className="bg-white p-6 rounded-lg shadow-lg">
            {limitOrders.map((activity) => (
              <li
                key={activity.id}
                className="flex flex-col py-4 border-b border-gray-200 last:border-0"
              >
                {activity.order_items && activity.order_items.length > 0 ? (
                  activity.order_items.map((item, index) => (
                    <div key={index} className="mb-2">
                      <span className="text-gray-600">{item.product_name || 'N/A'}</span>
                      <br />
                      <span className="text-gray-400 text-sm">
                        {item.updated_at
                          ? new Date(item.updated_at).toLocaleString()
                          : 'No update info'}
                      </span>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400">No items found</p>
                )}

              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OverView;
