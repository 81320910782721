import React from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const WishlistButton = ({ product, wishlist, setWishlist, token, API_BASE_URL }) => {
  // Remove from wishlist handler
  const removeFromWishlist = async (productId) => {
    try {
      await axios.get(`${API_BASE_URL}/delete_wishlist_item/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('productId',productId);

      // Update wishlist state
      setWishlist((prev) => prev.filter((item) => item.id !== productId));

      toast.success("Item removed from wishlist!", {
        position: "center",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error removing item from wishlist:", error);
      toast.error("Failed to remove item from wishlist.");
    }
  };

  // Add to wishlist handler
  const handleAddToWishlist = async (product) => {
    try {
      await axios.post(
        `${API_BASE_URL}/add_to_wishlist`,
        { product_id: product.id, quantity: 1 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update wishlist state
      setWishlist((prev) => [...prev, { ...product, product_id: product.id }]);

      toast.success(`${product.name} added to your wishlist!`, {
        position: "center",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error adding item to wishlist:", error);
      toast.error("Failed to add item to wishlist.");
    }
  };

  // Determine if product is in wishlist
  const isInWishlist = wishlist.some((item) => item.product_id === product.id);

  return isInWishlist ? (
    <button
      className="text-black"
      onClick={() => {
        const productId = wishlist.find((item) => item.product_id === product.id).id;
        removeFromWishlist(productId);
      }}
    >
      <FaHeart />
    </button>
  ) : (
    <button
      className="text-gray-400"
      onClick={() => handleAddToWishlist(product)}
    >
      <FaRegHeart />
    </button>
  );
};

export default WishlistButton;
