import React, { useEffect, useState } from 'react';
import API_BASE_URL from '../../global/apiConfig';
import { useGlobal } from '../../global/GlobalContext';

const Myorders = () => {
  const { token } = useGlobal();
  const [orders, setOrders] = useState([]);

useEffect(() =>{
  fetch(`${API_BASE_URL}/get_my_orders`, {
    headers: {
      Authorization: `Bearer ${token}`
      }
  })
  .then(response => response.json())
  .then((data) => {
      if(data.status){
        setOrders(data.orders);
      }
    })
},[])




  return (
        <>
        {/* {orders.length > 0 ? (
          <div>
            <h2>My Orders</h2>
            {orders.map((order) => (
              <div key={order.id} className="order-card">
                <h3>Order ID: {order.id}</h3>
                <p>
                  <strong>Customer:</strong> {order.customer.first_name} {order.customer.last_name}
                </p>
                <p>
                  <strong>Order Date:</strong> {order.order_date}
                </p>
                <p>
                  <strong>Total Amount:</strong> ₹{order.bill_amt}
                </p>
                <p>
                  <strong>Net Amount:</strong> ₹{order.net_amt}
                </p>
                <div className="order-items">
                  <h4>Order Items:</h4>
                  {order.order_items.map((item) => (
                    <div key={item.id} className="order-item">
                      <p>
                        <strong>Product:</strong> {item.product_name}
                      </p>
                      <p>
                        <strong>Quantity:</strong> {item.quantity}
                      </p>
                      <p>
                        <strong>Price:</strong> {item.price}
                      </p>
                      <p>
                        <strong>Total:</strong> ₹{item.total_price}
                      </p>
                    </div>
                  ))}
                </div>
                <p>
                  <strong>Shipping Status:</strong> {order.shipping_status}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p>Add Your Orders</p>
        )} */}

{orders.length > 0 ? (
  <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
    <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">My Orders</h2>
    {orders.map((order) => (
      <div
        key={order.id}
        className="bg-white p-4 rounded-lg shadow-md mb-6 border border-gray-200"
      >
        <h3 className="text-lg font-semibold text-gray-700">
          Order ID: <span className="text-blue-500">{order.id}</span>
        </h3>
        <p className="text-sm text-gray-600 mt-2">
          <strong>Customer:</strong> {order.customer.first_name} {order.customer.last_name}
        </p>
        <p className="text-sm text-gray-600">
          <strong>Order Date:</strong> {order.order_date}
        </p>
        <p className="text-sm text-gray-600">
          <strong>Total Amount:</strong> ₹{order.bill_amt}
        </p>
        <p className="text-sm text-gray-600">
          <strong>Net Amount:</strong> ₹{order.net_amt}
        </p>
        <div className="order-items mt-4">
          <h4 className="text-md font-medium text-gray-700 mb-2">Order Items:</h4>
          {order.order_items.map((item) => (
            <div
              key={item.id}
              className="p-3 bg-gray-50 rounded-md shadow-sm mb-2 border border-gray-300"
            >
              <p className="text-sm text-gray-700">
                <strong>Product:</strong> {item.product_name}
              </p>
              <p className="text-sm text-gray-700">
                <strong>Quantity:</strong> {item.quantity}
              </p>
              <p className="text-sm text-gray-700">
                <strong>Price:</strong> ₹{item.price}
              </p>
              <p className="text-sm text-gray-700">
                <strong>Total:</strong> ₹{item.total_price}
              </p>
            </div>
          ))}
        </div>
        <p className="mt-4 text-sm text-gray-600">
          <strong>Shipping Status:</strong>{" "}
          <span
            className={`${
              order.shipping_status === "Delivered"
                ? "text-green-500"
                : order.shipping_status === "Pending"
                ? "text-yellow-500"
                : "text-red-500"
            } font-medium`}
          >
            {order.shipping_status}
          </span>
        </p>
      </div>
    ))}
  </div>
) : (
  <p className="text-center text-gray-600 mt-10">
    No orders yet. Add your orders to see them here.
  </p>
)}

      </>
  );
};

export default Myorders;
