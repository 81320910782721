import './App.css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import React from 'react';
import Base from './pages/Base';
import Checkout from './pages/Checkout';
import ProductPage from './pages/ProductPage';
import ProductDetails from './pages/ProductDetails';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/user/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

import Login from './components/user/Login';
import Register from './components/user/Register';
import Profile from './components/user/Profile';
import Wishlist from './pages/Wishlist';  // Correct import for the Wishlist page component
import Cart from './pages/Cart';
import ThankYou from './pages/ThankYou';
import NotFound from './pages/NotFound';
import { LoadingProvider } from './global/LoadingContext';
import LoadingWrapper from './global/LoadingWrapper';
import Loader from './components/Loader';
import ScrollToTop from './components/ScrollToTop';
import SearchPage from './pages/SearchPage';
// mobile
import MensHome from './pages/mobile/MensHome';
import WomensHome from './pages/mobile/WomensHome';
import WinterHome from './pages/mobile/WinterHome';


function App() {
    return (
      <LoadingProvider>
        <AuthProvider>
        <Loader />
        <LoadingWrapper>
        <ScrollToTop />
          <Routes>
            <Route path="/search" element={<SearchPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/wishlist" element={<ProtectedRoute><Wishlist /></ProtectedRoute>} />
            <Route path="/cart" element={<Cart />} />
            {/* <Route path="/u/profile" element={<Profile />} /> */}
            <Route path="/myaccount" element={<ProtectedRoute><Profile section="Overview" /></ProtectedRoute>} />
            <Route path="/myaccount/orders" element={<ProtectedRoute><Profile section="My Orders" /></ProtectedRoute>} />
            <Route path="/myaccount/payments" element={<ProtectedRoute><Profile section="My Payments" /></ProtectedRoute>} />
            <Route path="/myaccount/wallet" element={<ProtectedRoute><Profile section="My Wallet" /></ProtectedRoute>} />
            <Route path="/myaccount/addresses" element={<ProtectedRoute><Profile section="My Addresses" /></ProtectedRoute>} />
            <Route path="/myaccount/profile" element={<ProtectedRoute><Profile section="My Profile" /></ProtectedRoute>} />
            <Route path="/logout" element={<Profile section="Logout"/>} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Base />} />
            <Route path="/products" element={<ProductPage showAll={true} />} />
            <Route path="/:categorySlug" element={<ProductPage />} />
            {/* <Route path="/products" element={<ProductPage />} /> */}
            <Route path="/p/:slug" element={<ProductDetails />} />
            <Route path="/thank-you" element={<ThankYou/>} />

            {/* mobile */}
            <Route path="/mens-home" element={<MensHome />} />
            <Route path="/womens-home" element={<WomensHome />} />
            <Route path="/winter-home" element={<WinterHome />} />
            

            {/* Catch-all route for undefined pages */}
            <Route path="/404" element={<NotFound />} />
          </Routes>
          </LoadingWrapper>
        </AuthProvider>
        </LoadingProvider>
  );
  
}

export default App;
