import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGlobal } from '../global/GlobalContext';
import API_BASE_URL from './../global/apiConfig';

function ProductSizeGuide() {
  const [selectedSize, setSelectedSize] = useState('');
  const [productVariations, setProductVariations] = useState([]);
  const { token } = useGlobal();
  const { slug } = useParams();

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/get_slug_data/${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (data.status) {
          const variations = data.product_details?.product_variations || [];
          setProductVariations(variations);
          
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductDetails();
  }, [slug, token]);

 
  return (
    <div className="product-size-guide bg-white rounded-lg p-4">
      {/* Color Selection */}
      <div className="w-full sm:w-1/3 mt-3 relative">
        <div className="flex justify-between mb-2 flex-col">
          <span className="text-gray-700 font-semibold text-sm">Colour Options:</span>
          <span className="text-gray-600 flex gap-2 mt-2">
          {productVariations.length > 0 ? (
  productVariations
    .filter((color) => color.color !== null && color.color_page_link !== null) // Filter out null values
    .map((color) => (
      <Link
        className="w-5 h-5 rounded-full block"
        to={color.color_page_link}
        key={color.color_id}
        style={{ backgroundColor: color.color }}
      >
        {/* Optional span if needed */}
        {/* <span style={{ color: `${color.color}` }}>{color.color}</span> */}
      </Link>
    ))
) : null} {/* Render nothing if there are no valid colors */}


            
          </span>
        </div>
        
      </div>

      {/* Size Selection */}
      <div className="mt-4">
        <label className="block text-gray-700 font-semibold text-sm">Select Size</label>
        <div className="mt-2 flex gap-2">
        {Array.isArray(productVariations) && productVariations.length > 0 ? (
          productVariations
            .filter((size) => size.size !== null)
            .map((size) => (
              <label
                key={size.size_id}
                className={`cursor-pointer relative w-6 h-6 border-1 rounded-sm flex items-center justify-center
                   transition-all duration-200 ease-in-out ${
                  selectedSize === size.size
                    ? 'border-black ring-2 ring-black'
                    : 'border-gray-300'
                }`}
              >
                <input
                  type="radio"
                  name="size"
                  value={size.size}
                  checked={selectedSize === size.size}
                  onChange={() => setSelectedSize(size.size)} // Handle click event
                  className="absolute opacity-0 -z-10"
                />
                <div className="flex items-center justify-center w-full h-full text-center font-semibold">
                  {size.size}
                </div>
              </label>
            ))
        ) : null} 
          
        </div>

      </div>
    </div>
  );
}

export default ProductSizeGuide;
