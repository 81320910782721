import React, { useState, useEffect } from "react";
import API_BASE_URL from "../../global/apiConfig";
import { useGlobal } from "../../global/GlobalContext";
import { useNavigate } from "react-router-dom";

const ShippingAddress = () => {
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const {token} = useGlobal();
  const navigate = useNavigate();
  const [newAddress, setNewAddress] = useState({
    address_label: "",
    full_name: "",
    email: "",
    mobile_number: "",
    alt_mob_number: "",
    address_line_1: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
  });

  // Fetch Shipping Addresses
  const fetchShipping = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/get_customer_shipping_addresses`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Failed to fetch shipping");
      const data = await response.json();
      setShippingAddresses(data.shipping_addresses);
      console.log('data.shipping_addresses',data.shipping_addresses);
    } catch (error) {
      console.error("Error fetching shipping:", error);
    }
  };

  useEffect(() => {
    fetchShipping();
  }, [API_BASE_URL, token]);

  // Add New Shipping Address
  const handleAddAddress = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/add_customer_shipping_addresses`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newAddress),
      });
      console.log('address', response);
      if (!response.ok) throw new Error("Failed to add shipping address");
      const data = await response.json();
      setShippingAddresses((prev) => [...prev, data.new_address]);
      setNewAddress({
        address_label: "",
        full_name: "",
        email: "",
        mobile_number: "",
        alt_mob_number: "",
        address_line_1: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
      });
      navigate('/checkout');
    } catch (error) {
      console.error("Error adding address:", error);
    }
  };

  // Delete Shipping Address
  const handleDeleteAddress = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/delete_customer_shipping_addresses/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Failed to delete address");
      setShippingAddresses((prev) => prev.filter((address) => address.id !== id));
    } catch (error) {
      console.error("Error deleting address:", error);
    }
  };

  return (
    <div>
      {shippingAddresses.length > 0 ? (
  <div className="flex flex-wrap gap-6">
    {shippingAddresses.map((ship) => ship? (
      <div
        key={ship.id}
        className="w-full sm:w-1/2 lg:w-1/3 p-4 border rounded-lg shadow-md bg-white"
      >
        <div className="font-semibold text-lg text-gray-800">{ship.full_name}</div>
        <div className="text-sm text-gray-600">{ship.address_label}</div>
        <div className="mt-2 text-gray-800">
          <p>{ship.address_line_1}</p>
          {ship.address_line_2 && <p>{ship.address_line_2}</p>}
          <p>
            {ship.city}, {ship.state} - {ship.zip_code}
          </p>
          {ship.country && <p>{ship.country}</p>}
        </div>
        <div className="mt-4 text-sm text-gray-500">
          <p>
            Email: <span className="text-gray-800">{ship.email}</span>
          </p>
          <p>
            Mobile: <span className="text-gray-800">{ship.mobile_number}</span>
          </p>
          {ship.alt_mob_number && (
            <p>
              Alternate Mobile: <span className="text-gray-800">{ship.alt_mob_number}</span>
            </p>
          )}
        </div>
        <div className="mt-4">
          {ship.is_default ? (
            <span className="text-sm text-green-600 font-semibold">Default Address</span>
          ) : (
            <span className="text-sm text-gray-500">Not Default</span>
          )}
        </div>
        <div className="mt-4">
          {/* Add delete button */}
          <button
            onClick={() => handleDeleteAddress(ship.id)}
            className="px-4 py-2 bg-red-500 text-white text-sm rounded hover:bg-red-600"
          >
            Delete Address
          </button>
        </div>
      </div>
    ) : null )}
  </div>
) : (
  <p>No shipping addresses found. Add a new one below:</p>
)}

      <form onSubmit={handleAddAddress} className="add-address-form my-4 p-4">
        <h3 className="my-4">Add New Shipping Address</h3>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Type of Address</label>
          <input
          className="w-[175px] border p-1 mb-3"
            type="text"
            placeholder="eg: home / office"
            value={newAddress.address_label}
            onChange={(e) => setNewAddress({ ...newAddress, address_label: e.target.value })}
            required
          />
        </div>
        <div className="gap-2 flex">
          <input
            className="w-1/2 border p-1 mb-3"
            type="text"
            placeholder="Full Name"
            value={newAddress.full_name}
            onChange={(e) => setNewAddress({ ...newAddress, full_name: e.target.value })}
            required
          />
          <input
            className="w-1/2 border p-1 mb-3"
            type="email"
            placeholder="Email"
            value={newAddress.email}
            onChange={(e) => setNewAddress({ ...newAddress, email: e.target.value })}
            required
          />
        </div>
        <div className="gap-2 flex">
          <input
          className="w-1/2 border p-1 mb-3"
            type="text"
            placeholder="Mobile Number"
            value={newAddress.mobile_number}
            onChange={(e) => setNewAddress({ ...newAddress, mobile_number: e.target.value })}
            required
          />
          <input
          className="w-1/2 border p-1 mb-3"
            type="text"
            placeholder="Alt Mobile Number (Optional)"
            value={newAddress.alt_mob_number}
            onChange={(e) => setNewAddress({ ...newAddress, alt_mob_number: e.target.value })}
          />
        </div>
        <div className="flex">
          <textarea
            type="text"
            className="w-full border p-1 mb-3"
            placeholder="Address Line 1"
            value={newAddress.address_line_1}
            onChange={(e) => setNewAddress({ ...newAddress, address_line_1: e.target.value })}
            required
          />
        </div>
        <div className="gap-2 flex">
          <input
            type="text"
            className="w-1/2 border p-1 mb-3"
            placeholder="City"
            value={newAddress.city}
            onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}
          />
          <input
            type="text"
            className="w-1/2 border p-1 mb-3"
            placeholder="State"
            value={newAddress.state}
            onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })}
          />
        </div>
        
        <div className="gap-2 flex">
          <input
          className="w-1/2 border p-1 mb-3"
            type="text"
            placeholder="Zip Code"
            value={newAddress.zip_code}
            onChange={(e) => setNewAddress({ ...newAddress, zip_code: e.target.value })}
          />
          <input
            type="text"
            className="w-1/2 border p-1 mb-3"
            placeholder="Country"
            value={newAddress.country}
            onChange={(e) => setNewAddress({ ...newAddress, country: e.target.value })}
          />
        </div>

        <button type="submit" className="bg-black text-white py-1 px-2 rounded-sm mt-2">Add Address</button>
      </form>
    </div>
  );
};

export default ShippingAddress;
