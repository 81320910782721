import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactOwlCarousel from 'react-owl-carousel';
import { useGlobal } from '../../global/GlobalContext';
import API_BASE_URL from '../../global/apiConfig';
import axios from 'axios';

const BannerCards = [
  {
    title: "mobileBanner1",
    imgSrc: `${process.env.PUBLIC_URL}/images/mobile/mobileBanner1.jpg`,
    slug: "#",
  },
  {
    title: "mobileBanner2",
    imgSrc: `${process.env.PUBLIC_URL}/images/mobile/mobileBanner2.jpg`,
    slug: "#",
  },
  // {
  //   title: "mobileBanner3",
  //   imgSrc: `${process.env.PUBLIC_URL}/images/mobile/mobileBanner3.jpg`,
  //   slug: "#",
  // },
  {
    title: "mobileBanner4",
    imgSrc: `${process.env.PUBLIC_URL}/images/mobile/mobileBanner4.jpg`,
    slug: "#",
  },
]


const options = {
    loop:true,
    autoplay:true,
    autoplayTimeout:5000,
    autoplayHoverPause:true,
    smartSpeed:2000,
    margin:10,
    nav:false,
    autoWidth:true,
    items:3,
  }

const CatMenMobile = () => {
    // const[ getCategory, setGetCategory ] = useState([]);
    // const { token } = useGlobal();
    // const [ loading, setLoading ] = useState(true);

    // const fetchCategory = useCallback(async () => {
    //     try {
    //       const response = await axios.get(`${API_BASE_URL}/get_slug_data/men`, {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       });
    //       const categoryData = response.data;
    //       setGetCategory(categoryData.category_data.child_categories);
    //       setLoading(false);
    //       console.log('categoryData:', categoryData.category_data.child_categories);
    //     } catch (error) {
    //       console.error('Error fetching category data:', error);
    //       setLoading(false);
    //     }
    //   }, [API_BASE_URL, token]);
    
    //   useEffect(() => {
    //     fetchCategory();
    //   }, [fetchCategory]);

      // if (loading) {
      //   return (
      //     <div className="flex justify-center items-center h-screen">
      //       <div className="text-black text-lg font-semibold">Loading Men Products...</div>
      //     </div>
      //   );
      // }


  return (
        <div className=''>
            <ReactOwlCarousel {...options} className='owl-theme inner_home owl_mobile' loop margin={10} >
                {BannerCards.map((bannercat, index) => (
                    <div className="item px-1" key={index}>
                    <Link to={`../${bannercat.slug}`} className="flex flex-col justify-end relative w-[375px] h-[450px] rounded-lg">
                        <img
                        className="w-full absolute h-full object-cover top-0 left-0 rounded-lg"
                        src={bannercat.imgSrc}
                        alt={bannercat.title}
                        />
                        {/* <h2>{bannercat.category_name}</h2> */}
                    </Link>
                    </div>
                ))}
        </ReactOwlCarousel>

        </div>
  );
};

export default CatMenMobile;
