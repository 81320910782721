import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaRegHeart, FaBagShopping, FaUser } from 'react-icons/fa6';
import { useAuth } from '../../user/AuthContext';
import { useGlobal } from '../../../global/GlobalContext';
import HeaderSearchBar from '../../HeaderSearchBar';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

const MobileHeader = ({title, Products}) => {
  const { userToken, logout } = useAuth();
  const { cart, wishlist } = useGlobal();

  const cartCount = cart.length;
  const wishlistCount = wishlist.length;

  return (
    <>


      {/* Navbar */}
      <header className="bg-white z-50 w-full sticky top-0">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 flex flex-wrap justify-between items-center">
          {/* mobile start */}
          <div className='flex gap-2 items-center'>
            <Link className='text-xl' to={'/'}>
              <BiChevronLeft />
            </Link>
              <div className='text-xs font-medium flex flex-col gap-1'>
                  {title}
                  <span className='text-[10px] font-light'>{Products?`${Products} Products` : ''}</span>
              </div>
          </div>
          
          

          {/* mobile end */}
 
          <div className="flex items-center gap-5">
            <div className="hidden md:flex">
              {userToken ? (
                <div className="items-center gap-3">
                  <div className="relative font-bold text-gray-800 group">
                    <Link to={'/myaccount'}>
                      <FaUser />
                    </Link>
                    
                    
                    <span className="hidden group-hover:block top-full left-0 border bg-white absolute p-2">
                      <Link className="text-black text-sm font-light text-nowrap" to={'/myaccount'}>My Account</Link>

                        <button
                          onClick={logout}
                          className="text-black text-sm font-light"
                        >
                          Logout
                        </button>
                    </span>                  
                  </div>
                </div>
              ) : (
                <Link to="/login">LOGIN / SIGNUP</Link>
              )}
            </div>

            <Link to="/wishlist" className="relative">
              <FaRegHeart className='text-black' />
              {wishlistCount > 0 && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-xs text-white bg-black rounded-full w-4 h-4 flex items-center justify-center">
                  {wishlistCount}
                </span>
              )}
            </Link>

            <Link to="/cart" className="relative">
              <FaBagShopping className='text-black'  />
              {cartCount > 0 && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-xs text-black border bg-white rounded-full w-4 h-4 flex items-center justify-center">
                  {cartCount}
                </span>
              )}
            </Link>
          </div>

          
        </div>
      </header>
    </>
  );
};

export default MobileHeader;

