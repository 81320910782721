import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import MainLayOut from '../layout/MainLayOut';
import API_BASE_URL from './../global/apiConfig';
import { useGlobal } from '../global/GlobalContext';

const Cart = ({ item }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useGlobal();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [currentItem, setCurrentItem] = useState(null);
  

  // Fetch Cart Data
  const fetchCart = async () => {

    const userToken = localStorage.getItem('userToken');
    if(userToken) {
      // setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/get_customer_cart`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('API Response:', response);
        console.log('token', token);
        setCartItems(Array.isArray(response.data.cart_items) ? response.data.cart_items : []);
      } catch (error) {
        console.error('Error fetching cart:', error.response?.data?.message || error.message);
        setCartItems([]); // Ensure the state is reset on error
      } finally {
        setLoading(false);
      }
    } else {
      try{
        setCartItems(JSON.parse(localStorage.getItem('localCart') || '[]'));
      } catch (error) {
        console.error('Error fetching cart:', error.message);
      } finally {
        setLoading(false);
      }
      
    }
    
  };
  useEffect(() => {
    if (isLoading) {
      console.log("items", cartItems);
    }
  }, [isLoading, cartItems]);

  useEffect(() => {
      fetchCart();
  }, []);

   // Recalculate totals when cartItems change
   const calculateSummary = () => {
    const totalMRP = cartItems.reduce(
      (acc, item) => acc + (item.regular_price || 0) * item.quantity,
      0
    );

    const subtotal = cartItems.reduce(
      (acc, item) => acc + (item.sale_price || 0) * item.quantity,
      0
    );

    const savings = totalMRP - subtotal;

    return { totalMRP, subtotal, savings };
  };

  const { totalMRP, subtotal, savings } = calculateSummary();

  // Update Quantity
  // const updateQuantity = async (itemId, variationId, quantity, price) => {
  //   console.log(`Sending data to backend: product_id: ${itemId}, prod_variation_id: ${variationId}, quantity: ${quantity}, price: ${price}`);
  //   try {
  //     const response = await axios.post(
  //       `${API_BASE_URL}/add_to_cart`,
  //       {
  //         product_id: itemId,
  //         prod_variation_id: variationId,
  //         quantity,
  //         price,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     console.log('API Response:', response.data);
  //     fetchCart();
  //   } catch (error) {
  //     console.error('Error updating quantity:', error.response?.data?.message || error.message);
  //   }
  // };
  const selectQuantity = async (itemId, variationId, quantity, price) => {
    console.log(`Setting quantity: product_id: ${itemId}, quantity: ${quantity}`);
    
    try {
      // Send the selected quantity to the backend to update the cart
      await axios.post(
        `${API_BASE_URL}/update_to_cart`,
        {
          product_id: itemId,
          prod_variation_id: variationId,
          quantity, // This will be the selected quantity
          price,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Quantity updated successfully');
      fetchCart(); // Refresh the cart after the update
      setIsPopupOpen(false); // Close the popup after submission
    } catch (error) {
      console.error('Error updating quantity:', error.message);
    }
  };
  

  

  // const increaseQuantity = (itemId) => {
  //   setCartItems((prevCartItems) =>
  //     prevCartItems.map((cartItem) =>
  //       cartItem.product_id === itemId
  //         ? { ...cartItem, quantity: cartItem.quantity + 1 }
  //         : cartItem
  //     )
  //   );
  
  //   // Find the item only once for the API update
  //   const item = cartItems.find((item) => item.product_id === itemId);
  //   if (item) {
  //     const newQuantity = item.quantity + 1;
  //     // Call API or update logic
  //     updateQuantity(itemId, item.prod_variation_id, newQuantity, item.sale_price);
  //   }
  // };
  
  
  

  // const decreaseQuantity = (itemId, variationId) => {
  //   const item = cartItems.find((item) => item.product_id === itemId);
  //   if (item && item.quantity > 1) {
  //     updateQuantity(item.product_id, item.prod_variation_id, item.quantity - 1, item.sale_price);
  //   } else if (!item) {
  //     console.error('Item not found:', itemId);
  //   } else {
  //     console.log('Minimum quantity reached for:', itemId);
  //   }
  // };

  // Remove Item from Cart
  const removeFromCart = async (cartId) => {
    try {
      await axios.get(`${API_BASE_URL}/delete_cart_item/${cartId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchCart(); // Refetch the cart after removing item
    } catch (error) {
      console.error('Error removing item from cart:', error.response?.data?.message || error.message);
    }
  };

  // const totalMRP = cartItems.reduce(
  //   (acc, item) => acc + (item.regular_price || 0) * item.quantity,
  //   0
  // );

  // const subtotal = cartItems.reduce(
  //   (acc, item) => acc + (item.sale_price || 0) * item.quantity,
  //   0
  // );
  // const savings = totalMRP - subtotal;

  const proceedHandler = () => {
    if(localStorage.getItem('userToken')) {
      navigate('/checkout');
      } else {
        navigate('/login');
      }
  }

  return (
    <MainLayOut>
      <div className="cart-page p-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg font-semibold">
          My Bag ({cartItems.length} Item{cartItems.length > 1 ? 's' : ''})
        </h2>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-blue-500"
              role="status"
            ></div>
          </div>
        ) : cartItems.length > 0 ? (
          <>
            <p className="text-green-600 my-2">Yay! You get FREE delivery on this order</p>
            <div className="flex md:flex-row flex-col">
              <div className="cart-items my-4 md:w-2/3 w-full">
                {cartItems.map((item) => (
                  <div
                    key={item.id}
                    className="cart-item flex items-start border-b pb-4 mb-4"
                  >
                    <div className="w-1/4 h-32 mr-4">
                    <Link to={`/p/${item.slug}`}>
                      <img
                          src={item.primary_img}
                          alt={item.prod_name}
                          className="w-full h-full object-cover rounded-lg"
                        />
                    </Link>
                     
                    </div>
                    <div className="flex-1">
                      <h3 className="text-md font-semibold">{item.prod_name}</h3>
                      <p className="text-gray-600 text-sm">Ships in 2-3 days</p>
                      {item.variation_name && (
                        <p className="text-gray-600 text-sm">Size: {item.variation_name}</p>
                      )}
                      <div>
                        <div className="flex items-center">
                          <span>{item.name}</span>
                          <button
                            onClick={() => {
                              setCurrentItem(item); // Set the item being updated
                              setIsPopupOpen(true); // Open the popup
                            }}
                            className="my-4 px-4 py-1 bg-black text-white rounded hover:bg-gray-700"
                          >
                            {item.quantity}
                          </button>
                        </div>

      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-80">
            <h2 className="text-xl font-bold mb-4">Select Quantity</h2>

            {/* Quantity Buttons */}
            <div className="grid grid-cols-5 gap-2 mb-4">
                {Array.from({ length: 10 }, (_, i) => i + 1).map((qty) => (
                  <button
                    key={qty}
                    onClick={() => setSelectedQuantity(qty)} // Set the selected quantity
                    className={`px-4 py-2 rounded ${selectedQuantity === qty ? 'bg-black text-white' : 'bg-gray-200 text-gray-700'}`}
                  >
                    {qty}
                  </button>
                ))}
              </div>

            {/* Submit and Close Buttons */}
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsPopupOpen(false)} // Close the popup
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
  onClick={async () => {
    if (currentItem) {
      setIsLoading(true);
      // console.log('currentItem',currentItem);

      try {
        // Call selectQuantity function and wait for it to complete
        await selectQuantity(
          currentItem.product_id,
          currentItem.prod_variation_id,
          selectedQuantity,
          currentItem.sale_price
        );
      } catch (error) {
        console.error("Error while selecting quantity:", error);
      } finally {
        // Always reset the loading state to false after operation
        setIsLoading(false);
      }
    }
  }}
  className={`px-4 py-2 rounded text-white ${
    isLoading ? "bg-gray-400 cursor-not-allowed" : "bg-black hover:bg-gray-700"
  }`}
  disabled={isLoading} // Disable button when loading
>
  {isLoading ? "Loading..." : "Submit"}
</button>

            </div>
          </div>
        </div>
      )}
    </div>

                      <div className="flex items-center gap-2 text-sm font-bold my-2">
                        <p className="text-black">₹{item.sale_price * item.quantity}</p>
                        {item.regular_price && (
                          <p className="text-gray-500 line-through">
                            ₹{item.regular_price * item.quantity}
                          </p>
                        )}
                      </div>
                      {item.regular_price > item.sale_price && (
                        <p className="text-green-500">
                          You saved ₹{(item.regular_price - item.sale_price) * item.quantity}
                        </p>
                      )}
                      <button
                        onClick={() => removeFromCart(item.id)}
                        className="text-red-500 text-sm underline mt-2"
                      >
                        Remove from Cart
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {isLoading ? (
  <div className="flex justify-center items-center h-screen">
    Loading....
  </div>
) : (
  <div className="price-summary p-4 border-t md:w-1/3 w-full">
    <h3 className="text-md font-semibold">PRICE SUMMARY</h3>
    <p className="text-gray-600 text-sm">Total MRP (Incl. of taxes): ₹{totalMRP}</p>
    <p className="text-gray-600 text-sm">Bag Discount: -₹{savings}</p>
    <p className="text-gray-600 text-sm">Delivery Fee: Free</p>
    <p className="font-bold text-black text-lg mt-2">Subtotal: ₹{subtotal}</p>
    <p className="text-green-600 mt-1">
      You are saving a total of ₹{savings} on this order
    </p>
    <button
      onClick={() => proceedHandler()}
      className="mt-4 w-full bg-black text-white p-2 rounded"
    >
      PROCEED
    </button>
  </div>
)}

              
            </div>
          </>
        ) : (
          <div className="text-center my-8">
            <p className="text-xl font-semibold">Your cart is empty</p>
            <p className="text-gray-600 mt-2">
              Looks like you haven't added anything to your cart yet.
            </p>

            <button
              onClick={() => navigate('/products')}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
            >
              Continue Shopping
            </button>
          </div>
        )}
      </div>
    </MainLayOut>
  );
};

export default Cart;
