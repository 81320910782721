import React, { Suspense, useCallback, useEffect, useState } from 'react'
import HomeBannerMobile from '../../components/mobileComponents/HomeBannerMobile'
import { Link } from 'react-router-dom'
import API_BASE_URL from '../../global/apiConfig'
import { useGlobal } from '../../global/GlobalContext'
import axios from 'axios'
import { FaArrowUpLong } from 'react-icons/fa6'
import { useInView } from 'react-intersection-observer'
import AllCatMenMobile from '../../components/mobileComponents/AllCatMobile'
import BottomFixedNav from '../../components/mobileComponents/BottomFixedNav'

const SuperCombedUnder = React.lazy(() => import('../../components/SuperCombedUnder499'));
const WardrobeMin45Off = React.lazy(() => import('../../components/WardrobeMin45Off'));
const StealNow = React.lazy(() => import('../../components/StealNow'));
const SuperCombedUnder399 = React.lazy(() => import('../../components/SuperCombedUnder399'));
const OversizedUnder499 = React.lazy(() => import('../../components/OversizedUnder499'));
const CloSaving49Off = React.lazy(() => import('../../components/CloSaving49Off'));
const CloSignatureTeesStartAt999 = React.lazy(() =>
  import('../../components/CloSignatureTeesStartAt999')
);
const JacquardCottonTeesStartAt299 = React.lazy(() =>
  import('../../components/JacquardCottonTeesStartAt299')
);


const LazyComponent = ({ component: Component }) => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Load when 10% of the component is visible
    triggerOnce: true, // Only load once
  });

  return (
    <div ref={ref}>
      {inView && (
        <Suspense fallback={<div>Loading...</div>}>
          <Component />
        </Suspense>
      )}
    </div>
  );
};

const MobileHomeBody = () => {

    const [ menCat, setMenCat ] = useState([]);
    const [ womenCat, setWomenCat ] = useState([]);
    const {token} = useGlobal();

    const fetchMen = useCallback(async() => {
        const response = await axios.get(`${API_BASE_URL}/get_slug_data/men`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                    }
                    });
                    setMenCat(response.data.category_data);
                    }, [token])
    const fetchWomen = useCallback(async() => {
        const response = await axios.get(`${API_BASE_URL}/get_slug_data/women`, {
            headers: {
                'Authorization': `Bearer ${token}`
                }
                });
                setWomenCat(response.data.category_data);
                }, [token])

                useEffect(() => {
                    fetchMen();
                    fetchWomen();
                }, [fetchMen, fetchWomen])



  return (
    <div>
        <div className=''>
            <HomeBannerMobile />
        </div>
        <img className='w-full' src='images/mobile/coupon.jpg' />
        <div className='ms-2 remove_nav'>
            <LazyComponent component={SuperCombedUnder} />
        </div>
        <div className='ms-2 remove_nav'><LazyComponent component={WardrobeMin45Off} /></div>
        <div className='flex gap-2 my-3'>
            <div className='men_cat_div w-1/2 ps-2 relative'>
                <Link to={'/men'}>
                    <h2 className='text-sm font-normal absolute top-2 right-2 text-left uppercase'>for him</h2>
                    <img src={menCat.cat_img} className='w-full rounded-xl h-40 object-top object-cover'  />
                </Link>
            </div>
            <div className='women_cat_div w-1/2 pe-2 relative'>
                <Link to={'/women'}>
                    <h2 className='text-sm font-normal absolute top-2 right-2 text-left w-full ps-3 uppercase'>for her</h2>
                    <img src={womenCat.cat_img} className='w-full rounded-xl h-40 object-top object-cover' />
                </Link>
            </div>
        </div>
        <div className='ms-2 remove_nav'><LazyComponent component={StealNow} /></div>
        <div className='ms-2 remove_nav'><LazyComponent component={SuperCombedUnder399} /></div>
        <div className='ms-2 remove_nav'><LazyComponent component={OversizedUnder499} /></div>
        <div className='ms-2 remove_nav'><LazyComponent component={CloSaving49Off} /></div>
        <div className='ms-2 remove_nav'><LazyComponent component={CloSignatureTeesStartAt999} /></div>
        <div className='ms-2 remove_nav'><LazyComponent component={JacquardCottonTeesStartAt299} /></div>
        <div className='ms-2 remove_nav'><AllCatMenMobile slug="men" /></div>
        <div className='ms-2 remove_nav'><AllCatMenMobile slug="women" /></div>
        <div>
          <BottomFixedNav />
        </div>
      
    </div>
  )
}

export default MobileHomeBody
