import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { Button, Container, Box, Typography, Alert, TextField, CircularProgress } from '@mui/material';
import MainLayOut from '../../layout/MainLayOut';
import API_BASE_URL from '../../global/apiConfig';
import { useGlobal } from '../../global/GlobalContext';
import { FaFacebook, FaGoogle } from 'react-icons/fa';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const { setToken } = useAuth();
  const { mergeLocalCartWithServer } = useGlobal();

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null); 

    try {
      const response = await fetch(`${API_BASE_URL}/webuser/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: email, password }),
      });

      const data = await response.json();

      if (data.status) {
        setToken(data.user_token);
        await mergeLocalCartWithServer(data.user_token);
      } else {
        setError(data.message || 'Login failed');
      }
    } catch (error) {
      setError('An error occurred while logging in.');
    } finally {
      setLoading(false);
    }
  };

  return (
    // <MainLayOut>
    //   <Container maxWidth="xs">
    //     <Box mt={5} p={3} bgcolor="white" boxShadow={3} borderRadius={2}>
    //       <Typography variant="h5" gutterBottom>
    //         Login
    //       </Typography>
    //       {error && <Alert severity="error">{error}</Alert>}
    //       <form onSubmit={loginUser}>
    //         <TextField
    //           fullWidth
    //           label="Email"
    //           variant="outlined"
    //           margin="normal"
    //           value={email}
    //           onChange={(e) => setEmail(e.target.value)}
    //         />
    //         <TextField
    //           fullWidth
    //           label="Password"
    //           variant="outlined"
    //           margin="normal"
    //           type="password"
    //           value={password}
    //           onChange={(e) => setPassword(e.target.value)}
    //         />
    //         <Box mt={2}>
    //           <Button
    //             fullWidth
    //             type="submit" // Change to 'submit' for the form
    //             variant="contained"
    //             color="primary"
    //             disabled={loading}
    //           >
    //             {loading ? <CircularProgress size={24} /> : 'Login'}
    //           </Button>
    //         </Box>
    //       </form>
    //     </Box>
    //   </Container>
    // </MainLayOut>
<MainLayOut>
    <Box 
      display="flex" 
      minHeight="100vh" 
      bgcolor="#f5f5f5"
    >
      {/* Left Side: Form */}
      <Box
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={4}
        bgcolor="white"
      >
        <Container maxWidth="xs">
          <Box mb={3}>
            <Typography align="center">
              Welcome to <br/>
              <span className='text-xl font-bold'>Clothology Global</span>
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              Login / register to continue
            </Typography>
          </Box>
          {/* <Box mb={2}>
            <Button 
              fullWidth 
              variant="contained" 
              color="primary" 
              style={{ backgroundColor: "#000", color: "#fff", marginBottom: "10px" }}
              startIcon={<FaFacebook/>} 
            >
              Continue with Facebook
            </Button>
            <Button 
              fullWidth 
              variant="contained" 
              color="secondary"
              startIcon={<FaGoogle/>} 
            >
              Continue with Google
            </Button>
          </Box>
          <Typography variant="body2" align="center" gutterBottom sx={{display:"flex"}}>
            <hr/> or <hr/>
          </Typography> */}
          {error && <Alert severity="error">{error}</Alert>}
          <form onSubmit={loginUser}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              margin="normal"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box mt={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{background:"black"}}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Login"}
              </Button>
            </Box>
          </form>
        </Container>
      </Box>

      {/* Right Side: Image */}
      <Box
        flex={1}
        position="relative"
        display={{ xs: "none", md: "block" }}
      >
        <Box
          component="img"
          src="images/horizontal/pexels-olly-975250.jpg"
          alt="Background"
          sx={{
            width: "100%",
            height: "100vh",
            objectFit: "cover",
          }}
        />
        {/* <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor="rgba(0, 0, 0, 0.6)" 
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <img 
              src="/path-to-your-logo.png" 
              alt="Brand Logo"
              style={{ width: "150px", marginBottom: "20px" }}
            />
            <Typography variant="h6" color="white" align="center">
              Your Brand Tagline
            </Typography>
          </Box>
        </Box> */}
      </Box>
    </Box>
    </MainLayOut>
  );
};

export default Login;
