import React, { createContext, useState, useContext, useEffect } from "react";
import API_BASE_URL from './apiConfig';
import axios from "axios";

const GlobalContext = createContext();

// Custom hook to use the CartContext
export const useGlobal = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {
      
      const [webToken, setWebToken] = useState(process.env.REACT_APP_WEB_TOKEN);
  
      const [cart, setCart] = useState([]);    
      const [ userCart, setUserCart ] = useState([]);
      const [products, setProducts] = useState([]);
      const [brands, setBrands] = useState([]);
      const [loading, setLoading] = useState(false);
      const [wishlist, setWishlist] = useState([]);
      // const [error, setError] = useState(null);
      // const [total, setTotal] = useState(0);
      // const [quantity, setQuantity] = useState(0);
      // const [showCart, setShowCart] = useState(false);
      // const [showCheckout, setShowCheckout] = useState(false);
      // const [showOrder, setShowOrder] = useState(false);
      // const [showOrderDetails, setShowOrderDetails] = useState(false);
      // const [showOrderSummary, setShowOrderSummary] = useState(false);
      // const [showOrderSummaryDetails, setShowOrderSummaryDetails] = useState(false);


      const [token, setToken] = useState(() => {
        return localStorage.getItem("userToken") || webToken;
      });

      useEffect(() => {
        if (token) {
          const userToken = localStorage.getItem("userToken");
      
          if (userToken) {
            // Fetch wishlist from the API if userToken is present
            const fetchWishlist = async () => {
              try {
                const response = await fetch(`${API_BASE_URL}/get_customer_wishlist`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                });
      
                if (!response.ok) {
                  throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
      
                const data = await response.json();
                setWishlist(data.wishlist || []); // Use default value if data.wishlist is undefined
              } catch (error) {
                console.error("Error fetching wishlist:", error);
              }
            };
      
            fetchWishlist();
          } else {
            // Fetch wishlist from localStorage if webToken is present
            const localWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
            setWishlist(localWishlist);
          }
        }
      }, [token]);

      useEffect(() => {
        if (token) {
          const userToken = localStorage.getItem("userToken");
      
          if (userToken) {
            const fetchCart = async () => {
              try {
                const response = await fetch(`${API_BASE_URL}/get_customer_cart`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                });
      
                if (!response.ok) {
                  throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
      
                const data = await response.json();
                console.log('test acrt', data);
                setCart(data.cart_items || []);
              } catch (error) {
                console.error("Error fetching wishlist:", error);
              }
            };
      
            fetchCart();
          } else {
            const localCartlist = JSON.parse(localStorage.getItem("localCartItems")) || [];
            setCart(localCartlist);
          }
        }
      }, [token]);

      useEffect(() => {
        const userToken = localStorage.getItem('userToken');
        if (userToken) {
          localStorage.setItem('userCart', JSON.stringify(cart));
          } else {
            localStorage.removeItem('userCart');
          }
      }, [])
      

      useEffect(() => {
        const fetchProducts = async () => {
          setLoading(true);
          await new Promise((resolve) => setTimeout(resolve, 1000)); 
          try {
            const response = await fetch(`${API_BASE_URL}/get_active_products`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },[]);
            const data = await response.json();
            console.log('tttesssttt', data);

            if (data.status) {
              setProducts(data.products);
              setBrands(data.brand_details);
            }
          } catch (error) {
            console.error('Error fetching products:', error);
          } finally {
            setLoading(false);
          }

            };

        fetchProducts();
    }, [token]);


  // const [cartItems, setCartItems] = useState(() => {
  //   return JSON.parse(localStorage.getItem('cartItems')) || [];
  // });
  const [wishlistItems, setWishlistItems] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('wishlistItems')) || [];
    } catch {
      return [];
    }
  });
  localStorage.removeItem('wishlistItems');
  const [savedItems, setSavedItems] = useState(() => {
    return JSON.parse(localStorage.getItem('savedItems')) || [];
  });

  // const [token, setToken] = useState(() => {
  //   return localStorage.getItem("userToken") || localStorage.getItem("webToken");
  // });

  const [apiurl, setApiurl] = useState(() => {
    return localStorage.getItem("apiurl");
  });

  // Sync cartItems to localStorage
  // useEffect(() => {
  //   localStorage.setItem("cartItems", JSON.stringify(cartItems));
  // }, [cartItems]);

  // Sync wishlistItems to localStorage
  // useEffect(() => {
  //   localStorage.setItem("wishlistItems", JSON.stringify(wishlistItems));
  // }, [wishlistItems]);

  // Sync savedItems to localStorage
  useEffect(() => {
    localStorage.setItem("savedItems", JSON.stringify(savedItems));
  }, [savedItems]);

  // Add item to cart or increase quantity if already in cart
  // const addToCart = (product) => {
  //   setCartItems((prevItems) => {
  //     const itemExists = prevItems.find((item) => item.id === product.id);
  //     if (itemExists) {
  //       return prevItems.map((item) =>
  //         item.id === product.id
  //           ? { ...item, quantity: item.quantity + 1 }
  //           : item
  //       );
  //     } else {
  //       return [...prevItems, { ...product, quantity: 1 }];
  //     }
  //   });
  // };

  
  const mergeLocalCartWithServer = async (userToken) => {
    const localCart = JSON.parse(localStorage.getItem('localCart') || '[]');

    if (!localCart.length) {
      console.log('No items in local cart to merge.');
      return;
    }

    try {
      for (const item of localCart) {
        await fetch(`${API_BASE_URL}/add_to_cart`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            product_id: item.id,
            prod_variation_id: null, // Adjust based on your logic
            quantity: item.quantity || 1,
            price: item.sale_price,
          }),
        });
      }

      console.log('Local cart successfully merged with server cart.');
      localStorage.removeItem('localCart'); // Clear localCart after merging
    } catch (error) {
      console.error('Error merging local cart with server cart:', error);
    }
  };

  const addToCart = async (product) => {
    const userToken = localStorage.getItem('userToken');
  
    if (!product || !product.id || !product.sale_price) {
      console.error('Invalid product details:', product);
      return;
    }
  
    if (userToken) {
      // Merge localCart with server cart if necessary
      await mergeLocalCartWithServer();
  
      // User is logged in
      try {
        const isAlreadyInCart = cart?.some((item) => item?.product_id === product.id);
  
        if (!isAlreadyInCart) {
          setCart((prevCart) => [...prevCart, product]);
  
          const response = await fetch(`${API_BASE_URL}/add_to_cart`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({
              product_id: product.id,
              prod_variation_id: null,
              quantity: 1,
              price: product.sale_price,
            }),
          });
  
          if (!response.ok) {
            throw new Error(`API error: ${response.statusText}`);
          }
  
          const data = await response.json();
          if (data.status) {
            console.log('Product added to server cart');
          } else {
            console.error('Failed to add to server cart:', data.error_message);
            setCart((prevCart) => prevCart.filter((item) => item.product_id !== product.id));
          }
        } else {
          console.log('Product already in cart');
        }
      } catch (error) {
        console.error('Error adding to cart:', error);
        setCart((prevCart) => prevCart.filter((item) => item.product_id !== product.id));
      }
    } else {
      // User is not logged in
      const localCart = JSON.parse(localStorage.getItem('localCart')) || [];
      const itemExists = localCart.find((item) => item.product_id === product.id);
  
      if (itemExists) {
        const updatedCart = localCart.map((item) =>
          item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
        );
        localStorage.setItem('localCart', JSON.stringify(updatedCart));
      } else {
        const updatedCart = [...localCart, { ...product, quantity: 1 }];
        localStorage.setItem('localCart', JSON.stringify(updatedCart));
      }
  
      setCart(JSON.parse(localStorage.getItem('localCart')) || []);
      console.log('Product added to local cart');
    }
  };
  
  
  // Add item to wishlist
  const addToWishlist = async (product) => {
    const isAlreadyInWishlist = wishlist.some((item) => item.product_id === product.id);

    console.log('iw', isAlreadyInWishlist);
  
    if (!isAlreadyInWishlist) {
      try {
        const response = await fetch(`${API_BASE_URL}/add_to_wishlist`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            product_id: product.id,
            quantity: 1,
          }),
        });

        console.log('response', response);
  
        const data = await response.json();
        console.log(data);
        if (data.status) {
          setWishlist([...wishlist, product]);
        } else {
          console.error('Failed to add to wishlist:', data.message);
        }
      } catch (error) {
        console.error('Error adding to wishlist:', error);
      }
    } else {
      setWishlist(wishlist.filter((item) => item.product_id !== product.id));
    }
  };
  
  

  // Add item to wishlist
  // const addToWishlist = async (product) => {
  //   const isAlreadyInWishlist = wishlist.some((item) => {
  //     console.log('item.id', item.id);
  //     return item.product_id === product.id;
  //   })
      
    
  
  //   console.log('isAlreadyInWishlist:', isAlreadyInWishlist);
  
  //   if (!isAlreadyInWishlist) {
  //     try {
  //       const response = await fetch(`${API_BASE_URL}/add_to_wishlist`, {
  //         method: 'POST',
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'Content-Type': 'application/json',
  //           Accept: 'application/json',
  //         },
  //         body: JSON.stringify({
  //           product_id: product.id,
  //           quantity: 1,
  //         }),
  //       });
  
  //       const data = await response.json();
  //       if (data.status) {
  //         setWishlist((prevWishlist) => [...prevWishlist, product]);
  //       } else {
  //         console.error('Failed to add to wishlist:', data.message);
  //       }
  //     } catch (error) {
  //       console.error('Error adding to wishlist:', error);
  //     }
  //   } else {
  //     try {
  //       console.log('Attempting to delete product ID:', product.id);
  
  //       const response = await axios.get(`${API_BASE_URL}/delete_wishlist_item/${product.id}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  
  //       if (response.data.status) {
  //         setWishlist((prevWishlist) => prevWishlist.filter((item) => item.id !== product.id));
  //         console.log(`Item with ID ${product.id} removed from wishlist`);
  //       } else {
  //         console.error('Failed to remove from wishlist:', response.data.message);
  //       }
  //     } catch (error) {
  //       console.error('Error removing item from wishlist:', error);
  //     }
  //   }
  // };
  
  
  
  
  

  // Remove item from wishlist
  const removeFromWishlist = (productId) => {
    setWishlistItems((prevItems) =>
      prevItems.filter((item) => item.product_id !== productId)
    );
  };

  // Increase quantity of a specific item in cart
  const increaseQuantity = (productId) => {
    setCart((prevItems) =>
      prevItems.map((item) =>
        item.id === productId
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  // Decrease quantity of a specific item in cart
  const decreaseQuantity = (productId) => {
    setCart((prevItems) =>
      prevItems
        .map((item) =>
          item.id === productId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
        .filter((item) => item.quantity > 0) // Remove items with quantity 0
    );
  };

  // Save item for later
  const saveForLater = (productId) => {
    setCart((prevItems) => {
      const itemToSave = prevItems.find((item) => item.id === productId);
      if (itemToSave) {
        setSavedItems((prevSaved) => [...prevSaved, itemToSave]);
      }
      return prevItems.filter((item) => item.id !== productId);
    });
  };

  // Compute counts
  const cartCount = cart.reduce((acc, item) => acc + item.quantity, 0);
  console.log('cart', cart);
  const wishlistCount = wishlistItems.length;

  const totalAmount = cart.reduce((total, item) => total + item.sale_price * item.quantity, 0);

  // Add totalMRP, subtotal, and savings to the context
  const totalMRP = cart.reduce((acc, item) => acc + item.regular_price * item.quantity, 0);
  const subtotal = cart.reduce((acc, item) => acc + item.sale_price * item.quantity, 0);
  const savings = totalMRP - subtotal;

  // Logout functionality
  const logout = () => {
    setToken("webToken");
    localStorage.removeItem("userToken");
  };

  return (
    <GlobalContext.Provider
      value={{
        products,
        wishlistItems,
        mergeLocalCartWithServer,
        wishlist,
        setWishlist,
        cart,
        setCart,
        savedItems,
        cartCount,
        wishlistCount,
        token,
        apiurl,
        addToCart,
        addToWishlist,
        removeFromWishlist,
        increaseQuantity,
        decreaseQuantity,
        saveForLater,
        totalAmount,
        totalMRP, 
        subtotal, 
        savings,
        logout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
