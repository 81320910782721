import React, { useEffect, useState } from 'react';
import { useGlobal } from '../../global/GlobalContext';
import API_BASE_URL from '../../global/apiConfig';
import { TextField } from '@mui/material';

const MyProfile = () => {
  const { token } = useGlobal();
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/get_customer_details`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        console.log('data', data);
        setProfile(data.customer_data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchProfile();
  }, [token]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-black text-lg font-semibold">Loading profile...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500 text-lg font-semibold">Failed to load profile. Please try again later.</div>
      </div>
    );
  }

  return (
    <>
    <div className='profile_form'>

     {/* <div className="min-h-screen profile_form bg-gray-50 py-8">
     <div className="max-w-3xl mx-auto p-4 bg-white shadow rounded-lg"> */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Section: Form Fields */}

          <div className="w-full">
            <TextField
              label="First Name*"
              value={profile.first_name}
              slotProps={{
                input: {
                  readOnly: true,
                  style: {
                    pointerEvents: 'none',
                    border: 'none',
                    outline: 'none',
                    fontSize: '15px',
                    color: '#666',
                  },
                },
              }}
              fullWidth
              aria-readonly
            />
          </div>
          <div className="w-full">
            <TextField
              label="Last Name*"
              value={profile.last_name}
              slotProps={{
                input: {
                  readOnly: true,
                  style: {
                    pointerEvents: 'none',
                    border: 'none',
                    outline: 'none',
                    fontSize: '15px',
                    color: '#666',
                  },
                },
              }}
              fullWidth
              aria-readonly
            />
          </div>
          
  
        {/* Right Section: Static Info */}
        {/* <div>
          <p className="text-gray-600">
            <strong>Alternate Mobile:</strong>{' '}
            {profile.alt_mob_number || 'N/A'}
          </p>
          <p className="text-gray-600">
            <strong>City:</strong> {profile.city}
          </p>
          <p className="text-gray-600">
            <strong>State:</strong> {profile.state}
          </p>
          <p className="text-gray-600">
            <strong>Zip Code:</strong> {profile.zip_code}
          </p>
        </div> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
        <div className="w-full">
            <TextField
              label="Email*"
              value={profile.email}
              slotProps={{
                input: {
                  readOnly: true,
                  style: {
                    pointerEvents: 'none',
                    border: 'none',
                    outline: 'none',
                    fontSize: '15px',
                    color: '#666',
                  },
                },
              }}
              fullWidth
              aria-readonly
            />
          </div>
          <div className="w-full">
            <TextField
              label="Phone*"
              value={profile.mobile_number}
              slotProps={{
                input: {
                  readOnly: true,
                  style: {
                    pointerEvents: 'none',
                    border: 'none',
                    outline: 'none',
                    fontSize: '15px',
                    color: '#666',
                  },
                },
              }}
              fullWidth
              aria-readonly
            />
          </div>
      </div>
  
      {/* Address Section */}
      {/* <div className="mt-6">
        <h4 className="text-lg font-medium text-gray-700 mb-2">Address</h4>
        <p className="text-gray-600">
          {profile.address_line_1}, {profile.address_line_2}
        </p>
        <p className="text-gray-600">
          <strong>Country:</strong> {profile.country}
        </p>
      </div> */}
  
      {/* Timestamps Section */}
      {/* <div className="mt-6">
        <p className="text-gray-600">
          <strong>Profile Created At:</strong>{' '}
          {new Date(profile.created_at).toLocaleDateString()}
        </p>
        <p className="text-gray-600">
          <strong>Last Updated At:</strong>{' '}
          {new Date(profile.updated_at).toLocaleDateString()}
        </p>
      </div> */}
     {/* </div>
   </div> */}
   </div>
  </>
  );
};

export default MyProfile;
