import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MainLayOut from '../../layout/MainLayOut';
import { useAuth } from './AuthContext';
import { useGlobal } from '../../global/GlobalContext';
import ShippingAddress from './ShippingAddress';
import Myorders from './MyOrders';
import MyProfile from './MyProfile';
import OverView from './OverView';

const Profile = () => {
  const { userToken, logout } = useAuth();
  const { token } = useGlobal();
  const [shipping, setShipping] = useState([]);
  const [activeMenu, setActiveMenu] = useState('Overview');
  const navigate = useNavigate();
  const location = useLocation();
  const pathToMenu = {
    '/myaccount': 'Overview',
    '/myaccount/orders': 'My Orders',
    '/myaccount/payments': 'My Payments',
    '/myaccount/wallet': 'My Wallet',
    '/myaccount/addresses': 'My Addresses',
    '/myaccount/profile': 'My Profile',
    '/logout': 'Logout',
  };
  useEffect(() => {
    const menu = pathToMenu[location.pathname];
    if (menu) {
      setActiveMenu(menu);
    }
  }, [location]);



  // const handleLogout = () => {
  //   localStorage.removeItem('userToken');
  //   navigate('/login');
  // };

  const renderContent = () => {
    switch (activeMenu) {
      case 'Overview':
        return <OverView />;
      case 'My Orders':
        return <div>
                <Myorders />
              </div>;
      // case 'My Payments':
      //   return <div>My Payments content goes here...</div>;
      // case 'My Wallet':
      //   return <div>My Wallet content goes here...</div>;
      case 'My Addresses':
        return (
            <ShippingAddress/>
        );
      
      case 'My Profile':
        return (
          <MyProfile />
        );
      case 'Logout':
        logout();
        break;
      default:
        return <div>Select a menu item</div>;
    }
  };

  return (
    <MainLayOut>
      <div className='bg-gray-100 py-12'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
      <div className="flex bg-gray-100">
        {/* Sidebar */}
        <div className="w-1/4 bg-white shadow-lg p-6 hidden md:block">
          <h2 className="text-2xl font-semibold mb-4">My Account</h2>
          <ul className="space-y-4">
            {[
              { label: 'Overview', path: '/myaccount' },
              { label: 'My Orders', path: '/myaccount/orders' },
              // { label: 'My Payments', path: '/myaccount/payments' },
              // { label: 'My Wallet', path: '/myaccount/wallet' },
              { label: 'My Addresses', path: '/myaccount/addresses' },
              { label: 'My Profile', path: '/myaccount/profile' },
              { label: 'Logout', path: '/logout' },
            ].map((menu) => (
              <li
                key={menu.label}
                className={`p-2 rounded-lg cursor-pointer text-gray-700 hover:bg-gray-200 ${
                  activeMenu === menu.label ? 'bg-gray-200 font-semibold' : ''
                }`}
                onClick={() => {
                  setActiveMenu(menu.label);
                  navigate(menu.path);
                }}
              >
                {menu.label}
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 p-8">
          <h3 className="text-sm md:text-base mb-4">{activeMenu}</h3>
          <div className="rounded-lg">
            {renderContent()}
          </div>
        </div>
      </div>
      </div>
      </div>
    </MainLayOut>
  );
};

export default Profile;
