import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import MainLayOut from '../layout/MainLayOut';
import API_BASE_URL from './../global/apiConfig';
// import { useCart } from '../components/CartContext';
import { useGlobal } from '../global/GlobalContext';
import CartButton from '../components/CartButton';
import { FaCross, FaDeleteLeft, FaRecycle, FaTrash, FaTrashArrowUp, FaTrashCan, FaTrashCanArrowUp } from 'react-icons/fa6';
import { FaRemoveFormat, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Wishlist = () => {
  const { cart,setCart, wishlist, addToCart, token, products, setWishlist } = useGlobal();

  const fetchCart = useCallback(async() => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get_customer_cart`, {
        headers: {
          Authorization: `Bearer ${token}`,
          },
          });
          console.log('cartxx',response)
          console.log('cart', response.data.cart_items);
          setCart(response.data.cart_items || []); // Assuming API returns cart array
          } catch (error) {
            console.error('Error fetching cart:', error);
          }
          },[]);
          useEffect(()=>{
            fetchCart();
          },[])


  // Remove item from Wishlist
  const removeFromWishlist = async (productId) => {
    try {
      await axios.get(`${API_BASE_URL}/delete_wishlist_item/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('productId', productId);
      setWishlist((prevItems) => prevItems.filter((item) => item.id !== productId));
      console.log(`Item with ID ${productId} removed from wishlist`);
    } catch (error) {
      console.error('Error removing item from wishlist:', error);
    }
  };

  // Debugging
  console.log('Wishlist Items in Wishlist Component:', wishlist);

  return (
    <MainLayOut>
      <div className="wishlist-page p-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg font-semibold">
          My Wishlist ({wishlist.length} Item{wishlist.length !== 1 ? 's' : ''})
        </h2>
        {wishlist.length > 0 ? (
          <div className="wishlist-items my-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {wishlist.map((item) => (
              <div key={item.id} className="wishlist-item border p-4 rounded-lg">
                <Link to={`../p/${item.product.slug}`}>
                  <img
                    src={item.product.primary_img}
                    alt={item.product.prod_name}
                    className="w-full h-48 object-cover rounded-lg"
                  />
                </Link>
                <h3 className="text-sm font-semibold mt-2 text-nowrap overflow-hidden mr-2">{item.product.prod_name}</h3>
                <div className='flex items-center gap-3 my-2'>
                  <p className="text-black text-sm font-bold">₹{item.product.sale_price}</p>
                  <p className="text-gray-500 line-through text-sm">₹{item.product.regular_price}</p>
                  <p className="text_hightlight text-xs">
                    {(
                      ((item.product.regular_price - item.product.sale_price) /
                      item.product.regular_price) *
                      100
                    ).toFixed(2)}{" "}
                    % OFF
                  </p>
                </div>
                
                <div className='flex justify-between border-t-2 pt-2'>
                <button
                  onClick={() => removeFromWishlist(item.id)}
                  className="mt-2 text-black text-sm underline"
                >
                  <FaTrashCan />
                </button>
                <CartButton
                  product={item.product}
                  cart={cart}
                  setCart={setCart}
                  token={token}
                  API_BASE_URL={API_BASE_URL}
                  fetchCart={fetchCart}
                  addToCart={addToCart}
                />
                </div>
                
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center my-8 text-gray-600">Your wishlist is empty!</p>
        )}
      </div>
    </MainLayOut>
  );
};

export default Wishlist;
